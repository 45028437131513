import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  baseUrl: string = environment.api;

  constructor(private httpClient: HttpClient) { }

  get_actualizaciones(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/actualizaciones?offset=0&limit=3&search=user')
      .pipe(map((data: any) => data));
  }

  get_practicas(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/practicas?offset=0&limit=30')
      .pipe(map((data: any) => data));
  }

  get_cargar(tag): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos?offset=0&limit=10&search=' + String(tag).toUpperCase())
      .pipe(map((data: any) => data));
  }

  get_cargar_productos(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos?offset=0&limit=15&search=PRODUCTOS')
      .pipe(map((data: any) => data));
  }

  get_cargar_reclamaciones(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos?offset=0&limit=15&search=RECLAMACIONES_INDEMNIZACIONES')
      .pipe(map((data: any) => data));
  }

  get_cargar_consultoria_estrategica(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos?offset=0&limit=15&search=CONSULTORIA_ESTRATEGICA')
      .pipe(map((data: any) => data));
  }

  get_cargar_consultoria_legal(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos?offset=0&limit=15&search=CONSULTORIA_LEGAL')
      .pipe(map((data: any) => data));
  }

  post_cambiar_password(request: any): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/auth/change-password', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  post_forgot_password(request: any): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/auth/forgot-password', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  post_registrar_usuario(request): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/auth/signup', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  refresh_token(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/auth/refresh', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  post_login(request): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/auth/signin', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  post_abrir_consulta(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/abrirconsulta', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  post_misconsultas(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/misconsultas', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_tiposdecasos(apertura): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos/' + apertura)
      .pipe(map((data: any) => data));
  }

  get_tiposdecasos_paginate(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/tiposdecasos/tendencias?offset=0&limit=10')
      .pipe(map((data: any) => data));
  }

  get_chat_full(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/chats/full/' + id)
      .pipe(map((data: any) => data));
  }

  get_expedientes_detail(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/expedientes/detail/' + id)
      .pipe(map((data: any) => data));
  }

  get_chat(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/chats')
      .pipe(map((data: any) => data));
  }

  post_chat(request): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/private/chats', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_partners(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/perfilawyers/best?offset=0&limit=10')
      .pipe(map((data: any) => data));
  }

  get_paybranch(tag): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/paybranch/' + tag)
      .pipe(map((data: any) => data));
  }

  patch_paybranch(recibo, request): Observable<any> {
    return this.httpClient.patch<any>(this.baseUrl + '/paybranch/' + recibo, request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_facturas(tag): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/facturas/' + tag)
      .pipe(map((data: any) => data));
  }

  post_facturas(tag, request): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/private/facturas/' + tag, request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_reclamaciones_indemnizaciones(apertura): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/misconsultas/main/reclamaciones_indemnizaciones?offset=0&limit=100')
      .pipe(map((data: any) => data));
  }

  get_consulta(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/misconsultas/main/CONSULTA?offset=0&limit=100')
      .pipe(map((data: any) => data));
  }


  get_comentarios(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/comentarios?comentariosId=' + id)
      .pipe(map((data: any) => data));
  }

  post_generar_cargo(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/metodosdepagos/generate_charged', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_casos_lawyer(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/casoslawyers')
      .pipe(map((data: any) => data));
  }

  post_casos_lawyer(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/casoslawyers', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_paybranch_saldos(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/paybranch/saldos')
      .pipe(map((data: any) => data));
  }

  patch_perfil_user(request): Observable<any> {
    return this.httpClient.patch(this.baseUrl + '/private/perfilusers/0', request)
      .pipe(map((data: any) => data));
  }

  get_metodos_pago(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/metodosdepagos?offset=0&limit=20')
      .pipe(map((data: any) => data));
  }

  patch_perfil_users(id, request): Observable<any> {
    return this.httpClient.patch(this.baseUrl + '/private/perfilusers/' + id, request)
      .pipe(map((data: any) => data));
  }

  get_perfil_users_profile(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/perfilusers/miprofile')
      .pipe(map((data: any) => data));
  }

  get_private_productos(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/misconsultas/main/productos?offset=0&limit=100')
      .pipe(map((data: any) => data));
  }

  get_private_reclamos(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/misconsultas/main/reclamaciones_indemnizaciones?offset=0&limit=100')
      .pipe(map((data: any) => data));
  }

  get_misconsultas(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/misconsultas/main2/byId/' + id)
      .pipe(map((data: any) => data));
  }

  post_reviews(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/public/reviews', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_reviews(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/public/reviews?search=' + id)
      .pipe(map((data: any) => data));
  }

  delete_metodos_pago(id): Observable<any> {
    return this.httpClient.delete(this.baseUrl + '/private/metodosdepagos/' + id)
      .pipe(map((data: any) => data));
  }

  get_community(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/comunidad')
      .pipe(map((data: any) => data));
  }

  set_community(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/comunidad/', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_soporte(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/soporte')
      .pipe(map((data: any) => data));
  }

  get_soporte_info(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/soporte/' + id)
      .pipe(map((data: any) => data));
  }

  post_ticket(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/tickets/', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  post_pregunta(request): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/private/abrirpreguntas/', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_preguntas(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/abrirpreguntas')
      .pipe(map((data: any) => data));
  }

  get_respuestas(id_pregunta): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/rr?id_pregunta=' + id_pregunta)
      .pipe(map((data: any) => data));
  }

  get_tickets(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/tickets')
      .pipe(map((data: any) => data));
  }

  get_ticket(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/tickets/' + id)
      .pipe(map((data: any) => data));
  }

  get_ticket_response(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/private/tickets_respuestas?search=' + id)
      .pipe(map((data: any) => data));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
