import { Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {
  seccion = 'home';
  @ViewChild('practicaId', { read: ViewContainerRef }) practicaId: ViewContainerRef;
  @Output () cerrar: EventEmitter<string> = new EventEmitter();
  contenido

  constructor(private publicService: PublicService) { }

  ngOnInit(): void {
        //Carga stipos de soporte
        console.log(this.practicaId);
        this.publicService.get_soporte_info(this.practicaId).subscribe(data => {
          console.log(data);
          const dataSoporte = data;
          this.contenido = dataSoporte;
        }, error => console.log(error));
  }

  cerrarDetail(): void {
    this.cerrar.emit('true');
  }

}
