import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ChatModule } from './chat/chat.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmarAperturaDialog } from './chat/tabs/abrirconsulta/apertura/apertura.component';
import { environment } from '../environments/environment';
import { initFirebaseBackend } from './authUtils';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgxLoadingXConfig,
  POSITION,
  SPINNER,
  NgxLoadingXModule
} from 'ngx-loading-x';

import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DndGridOptions, DND_GRID_OPTIONS } from 'src/dnd-grid';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
initFirebaseBackend(environment.firebaseConfig);


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}
const ngxLoadingXConfig: NgxLoadingXConfig = {
  show: false,
  bgBlur: 2,
  bgColor: '#33445F',
  bgOpacity: 5,
  bgLogoUrl: '',
  bgLogoUrlPosition: POSITION.topLeft,
  bgLogoUrlSize: 100,
  spinnerType: SPINNER.circleSpinner,
  spinnerSize: 80,
  spinnerColor: '#000',
  spinnerPosition: POSITION.centerCenter
};

@NgModule({
    declarations: [
        AppComponent,
        ConfirmarAperturaDialog,
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        NgxLoadingXModule.forRoot(ngxLoadingXConfig),
        AppRoutingModule,
        ChatModule,
        FormsModule,
        MatBottomSheetModule,
        MatCheckboxModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatListModule,
        MatDialogModule,
        MatButtonModule,
        MatListModule,
        ReactiveFormsModule,
        MatInputModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NgbModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
        {
            provide: DND_GRID_OPTIONS,
            useValue: { rowCount: 100, colCount: 12, gapSize: 10 } as DndGridOptions,
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
