import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { PublicService } from 'src/app/public.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  form;
  formProfile;
  user;
  info_fiscal;
  response;
  error;
  seccion = 'home';
  prueba = 'prueba';
  submitted = false;
  email;
  btnLock = 1;
  idUsuario;
  bandera1 = false;

  constructor(public publicService: PublicService, public dialog: MatDialog, private http: HttpClient, private formBuilder: UntypedFormBuilder) {
  }
  ngOnInit(): void {
    this.publicService.get_perfil_users_profile().subscribe(data => {
      this.email = data.email;
      this.idUsuario = data.id;
      this.info_fiscal = data.profile_user ? data.profile_user.customer : {};
      this.user = data.profile_user ? data.profile_user  : {};
      console.log(data);
      this.formProfile = this.formBuilder.group({
        nombre: [this.user.nombre ? this.user.nombre : '', Validators.required],
        apellidos: [this.user.apellidos ? this.user.apellidos : '', Validators.required],
        ciudad: [this.user.ciudad ? this.user.ciudad : '', Validators.required],
        logo: [this.user.logo ? this.user.logo : '', Validators.required],
        ocupacion: [this.user.ocupacion ? this.user.ocupacion : '', Validators.required],
        pais: [this.user.pais ? this.user.pais : '', Validators.required],
        telefono: [this.user.telefono ? this.user.telefono : '', Validators.required],
        nombre_legal: [this.info_fiscal?.legal_name ? this.info_fiscal.legal_name : '', Validators.required],
        email_fiscal: [this.info_fiscal?.email ? this.info_fiscal?.email : '', Validators.required],
        rfc: [this.info_fiscal?.tax_id ? this.info_fiscal?.tax_id : '', [Validators.required]],
        cp: [this.info_fiscal?.address?.zip ? this.info_fiscal?.address.zip : '', Validators.required],
        ciudad_fiscal: [this.info_fiscal?.address?.city ? this.info_fiscal?.address.city : '', Validators.required],
        estado: [this.info_fiscal?.address?.state ? this.info_fiscal?.address.state : '', Validators.required],
        calle: [this.info_fiscal?.address?.street ? this.info_fiscal?.address.street : '', Validators.required],
        ext: [this.info_fiscal?.address?.exterior ? this.info_fiscal?.address.exterior : '', Validators.required],
        int: [this.info_fiscal?.address?.interior ? this.info_fiscal?.address.interior : '', Validators.required],
        municipio: [this.info_fiscal?.address?.municipality ? this.info_fiscal?.address.municipality : '', Validators.required],
        colonia: [this.info_fiscal?.address?.neighborhood ? this.info_fiscal?.address.neighborhood : '', Validators.required]
      });
      this.bandera1 = true;
    }, error => this.error = error);
  }

  hideUserProfile() {
    document.getElementById('profile-detail').style.display = 'none';
  }



  submit() {
    const d = this.dialog.open(ConfirmAlert, {
      width: '320px',
      data: { isTrue: true },
    });
    d.afterClosed().subscribe(result => {
      if (result) {
        this.submitted = true;
        this.btnLock = 2;
        this.response = {
          'avanzar': true,
          'email': this.email,
          'nombre': this.formProfile.value.nombre,
          'apellidos': this.formProfile.value.apellidos,
          'ocupacion': this.formProfile.value.ocupacion,
          'ciudad': this.formProfile.value.ciudad,
          'pais': this.formProfile.value.pais,
          'logo': this.formProfile.value.logo,
          'customer': {
            'tax_id': this.formProfile.value.rfc,
            'email': this.formProfile.value.email_fiscal,
            'address': {
              'zip': this.formProfile.value.cp,
              'city': this.formProfile.value.ciudad_fiscal,
              'state': this.formProfile.value.estado,
              'street': this.formProfile.value.calle,
              'country': 'MEX',
              'exterior': this.formProfile.value.ext,
              'interior': this.formProfile.value.int,
              'municipality': this.formProfile.value.municipio,
              'neighborhood': this.formProfile.value.colonia
            },
            'facturar': false,
            'legal_name': this.formProfile.value.nombre_legal,
            'tax_system': '601'
          },
          'telefono': this.formProfile.value.telefono
        };
        console.log(this.response);
        this.publicService.patch_perfil_users(this.idUsuario, this.response).subscribe(data => {
          window.location.reload();
        }, error => this.error = error);

        setTimeout(() => { document.getElementById('btnClose').click() }, 1000);
      }
    });
  }
}


@Component({
  selector: 'ConfirmAlert',
  template: `<h1 mat-dialog-title>¿Desea actualizar su información de perfil?</h1>
  <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Gracias</button>
  <button mat-button [mat-dialog-close]="data.isTrue" cdkFocusInitial>Si</button>
</div>`,
})
export class ConfirmAlert {
  constructor(public dialogRef: MatDialogRef<ConfirmAlert>, @Inject(MAT_DIALOG_DATA) public data: DialogD,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogD {
  isTrue: boolean;
}
