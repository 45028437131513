import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule, NgbTooltipModule, NgbRating } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmarPagoDialog} from './misconsultas/consulta/consulta.component';
import {
  NgxLoadingXConfig,
  POSITION,
  SPINNER,
  NgxLoadingXModule
} from 'ngx-loading-x';

import { alertaCancelacionFacturas, alertaEnviarFactura, alertaError, alertaFacturarRecibo, BottomSheetOverviewExampleSheet } from './facturas-recibos/facturas-recibos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { ReviewsComponent } from './reviews/reviews.component';
import { AddWidgetService, DND_GRID_OPTIONS, MoveWidgetService, ResizeWidgetService, StateService } from 'src/dnd-grid';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { SafePipe } from 'src/app/trusthtml.pipe';
import { HighlightDirective } from '../highlight.directive';
import { VideoRecordingService } from 'src/app/video-recording.service';

const ngxLoadingXConfig: NgxLoadingXConfig = {
  show: false,
  bgBlur: 2,
  bgColor: '#33445F',
  bgOpacity: 5,
  bgLogoUrl: '',
  bgLogoUrlPosition: POSITION.topLeft,
  bgLogoUrlSize: 100,
  spinnerType: SPINNER.circleSpinner,
  spinnerSize: 80,
  spinnerColor: '#000',
  spinnerPosition: POSITION.centerCenter
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [SafePipe,
    HighlightDirective, ConfirmarPagoDialog, alertaError, alertaCancelacionFacturas, alertaEnviarFactura,alertaFacturarRecibo, BottomSheetOverviewExampleSheet, ReviewsComponent ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbRating,
    PerfectScrollbarModule,
    FormsModule,
    NgbTooltipModule,
    NgxLoadingXModule.forRoot(ngxLoadingXConfig),
    NgbModalModule,
    NgbCollapseModule,
    TranslateModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatListModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatRadioModule,
    MatTabsModule,
    MatButtonModule,
    MatBottomSheetModule
  ],
  exports: [MatListModule, MatRadioModule],
  providers: [VideoRecordingService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TabsModule { }
