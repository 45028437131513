import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';

import { WidgetType } from '../models';
import { AddWidgetSignService } from '../services-sign';

@Component({
  selector: 'dnd-widget-types-sign',
  templateUrl: './dnd-widget-types-sign.component.html',
  styleUrls: [ './dnd-widget-types-sign.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DndWidgetTypesSignComponent  {
  @Input()
  public widgetTypes: WidgetType[];

  @ContentChild(TemplateRef)
  public widgetTypeTemplate: TemplateRef<any>;

  constructor(
    private addWidgetSignService: AddWidgetSignService
  ) {}

  public trackWidgetType(index: number, widgetType: WidgetType): string {
    return widgetType.id;
  }

  public onDragStart(event: DragEvent, widgetType: WidgetType): void {
    this.addWidgetSignService.onDragStart(event, widgetType);
  }

  public onDragEnd(event: DragEvent, widgetType: WidgetType): void {
    this.addWidgetSignService.onDragEnd(event, widgetType);
  }
}
