import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { AperturaComponent } from './../apertura/apertura.component';
import { HttpClient } from '@angular/common/http'; // <----
import { environment } from '../../../../../../src/environments/environment';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
/**
 * Tabs-Profile component
 */
export class DetalleComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrarDetalle: EventEmitter<string> = new EventEmitter();
  listPracticas: any;
  practicaTitulo: string;
  @Input() detalleId: string;
  private _ref;
  seccion = 'home';
  error;
  infoPregunta;
  detalle;
  nickname;
  pregunta = '';
  descripcion;
  idUsuario;
  lawyers;
  respuestas;
  tendencias;
  scrollable = 'scrollable1';
  @ViewChild('div', {static: true}) _div: ElementRef;
  private scrollChangeCallback: () => void;
  ancho = 178;
  id;

  @ViewChild('apertura', { read: ViewContainerRef }) apertura: ViewContainerRef;

  constructor(private modalService: NgbModal, private publicService: PublicService, private factoryResolver: ComponentFactoryResolver, private http: HttpClient, config: NgbCarouselConfig, private router: ActivatedRoute) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    this.id = this.router.snapshot.params.id;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(this.id, this.detalle);

    if (sessionStorage.getItem('token')) {
      this.scrollable = 'scrollable';
    }
    this.publicService.get_perfil_users_profile().subscribe(data => {
      console.log(data);
      this.nickname = data.profile_user.nombre;
      this.idUsuario = data.id_authguard;
    }, error => console.log(error));
    this.cargarDetalle();
    this.cargarLawyers();
    this.cargarTendencias();
  }

  cargarLawyers(): void {
    this.publicService.get_partners().subscribe(data => {
      this.lawyers = data.items;
    }, error => this.error = error);
  }

  cargarTendencias(): void {
    this.publicService.get_tiposdecasos_paginate().subscribe(data => {
      this.tendencias = data.items;
    }, error => this.error = error);
  }

  cargarDetalle(): void {
    console.log('DANIELL', this.detalleId);
    this.publicService.get_tiposdecasos(this.detalleId).subscribe(data => {
      this.detalle = data;
    }, error => this.error = error);
  }

  createQuestion(pregunta, desc) {
    console.log(pregunta, desc);
    let obj = {
      "motivo": pregunta,
      "descripcion": desc,
      "autor": this.nickname,
      "avatar": "",
      "idUsuario": this.idUsuario
    };
    this.publicService.post_pregunta(obj).subscribe(data => {
      window.location.reload();
    }, error => this.error = error);
  }

  openGroupModal(info: any, item) {
    this.modalService.open(info, { centered: true });
    this.infoPregunta = item;
    this.publicService.get_respuestas(item.id).subscribe(data => {
      console.log(data);
      this.respuestas = data.items;
    }, error => console.log(error));
  }

  abrirApertura(id): void {
    this.seccion = 'inicio';


    const factory = this.factoryResolver.resolveComponentFactory(AperturaComponent);
    this._ref = this.apertura.createComponent(factory);

    this._ref.instance.apertura = id;

    this._ref.instance.cerrarDetalle.subscribe(data => {
      this.apertura.clear();
      this.seccion = 'home';
    });
  }

  cerrarDetail(): void {
    if (localStorage.getItem('webview') === 'true') {
      const link = document.createElement('a');
      link.href = 'https://user.cuartocastejo.com/white';
      link.target = '_self';
      link.click()
    } else {
      this.cerrarDetalle.emit('true');
    }
  }

  ngAfterViewInit(): void{
    let IsMovil = true;
    if(IsMovil) {
    this.scrollChangeCallback = () => this.onContentScrolled(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
    }
  }

  onContentScrolled(e) {
    let scrollOffset = e.srcElement.scrollTop;
    var url = (<HTMLInputElement>document.getElementById('id1')).value;
    if (scrollOffset > 400) {
      document.getElementById("header").style.height = "40px";
      document.getElementById("header-contenido").style.display = "none";
      document.getElementById("header").style.background = "linear-gradient(rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.7)), url(" + url + ") no-repeat center center / cover";
    } else {
      document.getElementById("header").style.height = "215px";
      document.getElementById("header-contenido").style.display = "inherit";
      document.getElementById("header").style.background = "linear-gradient(rgba(0, 0, 0, 0.3),  rgba(0, 0, 0, 0.3)), url(" + url + ") no-repeat center center / cover";
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
  }

}
