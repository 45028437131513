<div class="layout-wrapper" style="display: flex !important">
  <!-- Start User chat -->
  <div class="user-chat w-100" id="chat-room">
    <div style="display: flex !important">
      <!-- start chat conversation section -->
      <div class="w-100">
        <div class="p-0 p-lg-4 border-bottom">
          <div class="row align-items-center">
            <div class="col-sm-6 col-6">
              <div class="d-flex align-items-center">
                <div class="d-block d-lg-none me-2 ms-0">
                  <a
                    href="javascript: void(0);"
                    class="user-chat-remove text-muted font-size-16 p-2"
                    (click)="closeUserChat()"
                    ><i class="ri-arrow-left-s-line"></i
                  ></a>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-12 mb-0 text-truncate">
                    <a href="#" class="text-reset user-profile-show">{{
                      nickname
                    }}</a>
                    <i
                      class="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"
                    ></i>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-6">
              <ul class="list-inline user-chat-nav text-end mb-0"></ul>
            </div>
          </div>
        </div>
        <!-- end chat user head -->

        <!-- start chat conversation -->
        <div class="chat-conversation p-3 p-lg-4" id="ventanachat">
          <div class="message-box" *ngFor="let chat of chats">
            <div
              class="chat-status"
              text-center
              *ngIf="chat.type === 'join' || chat.type === 'exit'; else message"
            >
              <span class="chat-date">{{ chat.date | date : "short" }}</span>
              <span class="chat-content-center">{{ chat.message }}----</span>
            </div>
            <ng-template #message>
              <div class="chat-message">
                <div class="container">
                  <div class="left-column">
                    <img
                    src="assets/images/users/avatar-1.jpg"
                    class="avatar"
                    *ngIf="chat.nickname !== nickname"
                  />
                  <img
                    src="assets/images/users/avatar-2.jpg"
                    class="avatar"
                    *ngIf="chat.nickname === nickname"
                  />
                  </div>
                  <div class="right-column">
                    <span class="msg-name">{{chat.nickname}}: </span>

                    <div
                    class="right-bubble"
                    [ngClass]="{
                      'left-bubble': chat.nickname === nickname,
                      'right-bubble': chat.nickname !== nickname
                    }"
                  >

                    <span class="msg-txt" [innerHTML]="chat.message"><br />

                    </span>

                  </div>
                  <span *ngIf="chat.date" style="font-size: 10px;; color: black;">
                    <span class="msg-date" *ngIf="chat.nickname === nickname">
                      <span *ngIf="esHoy(chat.date)">
                        {{ chat.date | date : "HH:mm" : "local" }}
                      </span>
                      <span *ngIf="!esHoy(chat.date)">
                        {{ chat.date | date : "yyyy-MM-dd HH:mm" : "local" }}
                      </span>
                    </span>
                    <span class="msg-date" *ngIf="chat.nickname !== nickname">
                      <span *ngIf="esHoy(chat.date)">
                        {{ chat.date | date : "HH:mm" : "local" }}
                      </span>
                      <span *ngIf="!esHoy(chat.date)">
                        {{ chat.date | date : "yyyy-MM-dd HH:mm" : "local" }}
                      </span>
                    </span>
                  </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- end chat conversation end -->
        <div class="ab"></div>
        <!-- start chat input section -->
        <form
          class="message-form"
          [formGroup]="chatForm"
          (ngSubmit)="onFormSubmit(chatForm.value)"
          style="width: 100%; bottom: 0px; position: absolute"
        >
          <div class="p-0 p-lg-6 border-top mb-0">
            <div class="row no-gutters">
              <div class="col">
                <div>
                  <input
                    matInput
                    type="text"
                    class="chatTcx"
                    placeholder="Enter Message..."
                    formControlName="message"
                    [errorStateMatcher]="matcher"
                  />
                </div>
              </div>
              <div class="col-auto">
                <div class="chat-input-links ms-md-2">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <button
                        type="submit"
                        [disabled]="!chatForm.valid"
                        class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
                      >
                        <i class="ri-send-plane-2-fill"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- end chat input section -->
      </div>
      <!-- end chat conversation section -->
    </div>
  </div>
</div>
