<div
  *ngFor="let widgetType of widgetTypes; trackBy: trackWidgetType"
  class="widget"
  draggable="true"
  [class.highlighted]="widgetType.isActive"
  (dragstart)="onDragStart($event, widgetType)"
  (dragend)="onDragEnd($event, widgetType)"
>
  <div [innerHTML]="widgetType.id"></div>
</div>
