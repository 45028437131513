import { ThemeChatComponent } from './../../themechat/themechat.component';
import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChatsComponent } from '../../chats/chats.component';
import { PublicService } from 'src/app/public.service';
import { MatTabGroup } from '@angular/material/tabs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-seguimiento-consulta',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.scss']
})
/**
 * Tabs-Profile component
 */
export class SeguimientoConsultaComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrar: EventEmitter<string> = new EventEmitter();
  listPracticas: any;
  practicaTitulo: string;
  @Input() practicaId: string;
  @Input() tabId: string;
  private _ref;
  activo = '1';
  seccion = 'home';
  colorToggle = 'primary';
  backgroundColorToggle = 'primary';
  room = '0';
  ancho = window.innerWidth;
  alto = window.innerHeight;
  error;
  number = 134;
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
  @ViewChild('chat', { read: ViewContainerRef }) chat: ViewContainerRef;
  expedientes;
  selected = new FormControl(0);


  name = 'Video events';
  videoSource = 'https://cuartocastejo.com/legal/presentacion.mp4';
  @ViewChild('videoPlayer') videoplayer: any;
  public startedPlay: boolean = false;
  public show: boolean = false;

  constructor(private publicService: PublicService, private factoryResolver: ComponentFactoryResolver, private http: HttpClient) {

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.cargarChat(this.practicaId);
    if (this.practicaId === 'penal') {
      this.practicaTitulo = 'Penal';
      this.listPracticas = [];
    } else if (this.practicaId === 'seguros') {
      this.listPracticas = [];
    } else {
      this.listPracticas = [];
    }
    console.log(this.tabId, this.practicaId);
    console.log(this.tabId);
    if (this.tabId === 'chat') {
      this.selected.setValue(2);
    }
  }

  getData(data: any) {
  }



  closebutton(videoplayer) {
    this.show = !this.show;
    videoplayer.nativeElement.play();
  }

  onSelectionChange (event) {
    console.log(event);
    if (event.index === 0) {
      this.cargarChat(this.practicaId);
    }
  }

  cargarChat(id): void {
    this.publicService.get_chat_full(id).subscribe(data => {
      this.room = data.nombre_room;
      this.cargarExpedientes(this.room);
      this.chat.clear();
      // Crea componente de chat
      const factory = this.factoryResolver.resolveComponentFactory(ThemeChatComponent);
      this._ref = this.chat.createComponent(factory);
      this._ref.instance.id = this.room;
      this._ref.instance.cerrar.subscribe(data => {
      });
    }, error => this.error = error);
  }

  cargarExpedientes(room): void {
    this.publicService.get_expedientes_detail(room).subscribe(data => {
      let exp = [];
      for (let e of data) {
        e.more = false;
        exp.push(e);
      }

      this.expedientes = exp;
    }, error => this.error = error);
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';
  }

  cerrarPractica(): void {
    this.cerrar.emit('true');
  }

}
