import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver, HostListener, ElementRef } from '@angular/core';
import { DetalleComponent } from './../detalle/detalle.component';
import { HttpClient } from '@angular/common/http'; // <----
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-practica',
  templateUrl: './practica.component.html',
  styleUrls: ['./practica.component.scss']
})
/**
 * Tabs-Profile component
 */
export class PracticaComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrar: EventEmitter<string> = new EventEmitter();
  listPracticas: any;
  listTendencias: any;
  practicaTitulo: string;
  @Input() practicaId: any;
  private _ref;
  seccion = 'home';
  number = 134;
  scrollable = 'scrollable1';
  @ViewChild('div', {static: true}) _div: ElementRef;
  private scrollChangeCallback: () => void;
  error;
  id;


  imagePractica;
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;

  constructor(public publicService: PublicService, private factoryResolver: ComponentFactoryResolver, private http: HttpClient, config: NgbCarouselConfig, private router: ActivatedRoute
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    this.id = this.router.snapshot.params.practicaId;
  }


  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (sessionStorage.getItem('token')) {
      this.scrollable = 'scrollable';
    }
    if (this.id) {
      this.publicService.get_practicas().subscribe(data => {
        for (var i = 0; i < data.items.length; i++) {
          if (data.items[i].tag == this.id) {
            this.practicaId = data.items[i];

            this.cargarPractica();
            this.cargarTendencias();
          }
        }
      }, error => this.error = error);
    } else {
      this.cargarPractica();
      this.cargarTendencias();
    }

  }

  cargarPractica(): void {
    this.publicService.get_cargar(this.practicaId.tag).subscribe(data => {
      this.listPracticas = data.items;
    }, error => this.error = error);
  }

  cargarTendencias(): void {
    this.publicService.get_tiposdecasos_paginate().subscribe(data => {
      this.listTendencias = data.items;
    }, error => this.error = error);
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';


    const factory = this.factoryResolver.resolveComponentFactory(DetalleComponent);
    this._ref = this.detalle.createComponent(factory);

    this._ref.instance.detalleId = id;
    this._ref.instance.cerrarDetalle.subscribe(data => {
      this.detalle.clear();
      this.seccion = 'home';
    });
  }

  cerrarPractica(): void {
    if (localStorage.getItem('webview') === 'true') {
      const link = document.createElement('a');
      link.href = 'https://user.cuartocastejo.com/white';
      link.target = '_self';
      link.click()
    } else {
      this.cerrar.emit('true');
    }
  }

  ngAfterViewInit(): void{
    let IsMovil = true;
    if(IsMovil) {
    this.scrollChangeCallback = () => this.onContentScrolled(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
    }
  }

  onContentScrolled(e) {
    let scrollOffset = e.srcElement.scrollTop;
    var url = (<HTMLInputElement>document.getElementById('id1')).value;
    if (scrollOffset > 400) {
      document.getElementById("header").style.height = "40px";
      document.getElementById("header-contenido").style.display = "none";
      document.getElementById("header").style.background = "linear-gradient(rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.7)), url(" + url + ") no-repeat center center / cover";
    } else {
      document.getElementById("header").style.height = "215px";
      document.getElementById("header-contenido").style.display = "inherit";
      document.getElementById("header").style.background = "linear-gradient(rgba(0, 0, 0, 0.3),  rgba(0, 0, 0, 0.3)), url(" + url + ") no-repeat center center / cover";
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
  }

}

