import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';

import { Chats } from './chats.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { SeguimientoConsultaComponent } from '../misconsultas/seguimiento/seguimiento.component';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})

export class ChatsComponent implements OnInit {
  seccion = 'home';
  chat: Chats[];
  error;
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
  private _ref;

  constructor(public publicService: PublicService, private http: HttpClient,private factoryResolver: ComponentFactoryResolver) { }



  ngOnInit(): void {
      this.publicService.get_chat().subscribe(data => {
        this.chat = data.items;
        console.log(this.chat);
      }, error => this.error = error);

  }

  abrirConsulta(id) {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(SeguimientoConsultaComponent);
    this._ref = this.detalle.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.tabId = 'chat';
    this._ref.instance.cerrar.subscribe(data => {
      this.detalle.clear();
    });
  }


  formatString(str) {
    // Replace "_" and ":" with a space
    str = str.replace(/_|:/g, ' ');

    // Convert the first letter to uppercase
    str = str.charAt(0).toUpperCase() + str.slice(1);

    return str;
  }
}
