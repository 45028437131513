export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: 'AIzaSyAAYosc6eSKVKlbod72b7HbcVrK3cvWk7U',
    authDomain: 'legalbranchchat.firebaseapp.com',
    databaseURL: 'https://legalbranchchat-default-rtdb.firebaseio.com',
    projectId: 'legalbranchchat',
    storageBucket: 'legalbranchchat.appspot.com',
    messagingSenderId: '483510419514',
    appId: '1:483510419514:web:8589051f2c09a4dfbbb503'
  },
  auth: 'https://cuartocastejo-services.up.railway.app/auth/signin',
  api: 'https://cuartocastejo-services.up.railway.app'
 // api: 'http://localhost:8080/api'
};
