<div *ngIf="seccion === 'home'">
  <div id="header_c">
    <textarea id="id1" style="display: none">
  https://esug.mx/legal/fiscal1.jpg</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarApertura()" style="cursor: pointer; float: left;"><svg fill="white"
                class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
            <span class="abrir-consulta">Abrir consulta</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Start profile content -->
<div class="body2">
  <div *ngIf="configuracion">
    <div class="progress-wrap progress">
      <div class="progress-bar progress"></div>
    </div>

    <div class="body1">
      <div class="verticalCarousel">
        <ul class="verticalCarouselGroup vc_list">
          <li>
            <div class="contenedor">
              <div class="hijo">
                <h4>Comentanos qué piensas</h4>
                <div class="ct">Gracias por aperturar con nosotros.</div>
                <div class="gLjXDS">El cuestionario no debería tardar más de 3 minutos<br><br></div>
                <button data-qa="start-button" tabindex="0"
                  class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"><span
                    class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"><span
                      class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"><span
                        class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ">Empecemos</span></span></span></button>
                <div aria-hidden="true" class="HelperWrapper-sc-__sc-1ra8r4w-1 eGsDJf">
                  <div class="ActionHelperWrapper-sc-__sc-1qx1n1u-0 grkOHx">
                    <div class="KeyTextWrapper-sc-__sc-1qx1n1u-1 uCrVC">
                      <div class="TextWrapper-sc-__sc-1f8vz90-0 dtbavj">pulsa <strong>Enter ↵</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li *ngFor="let steps of configuracion; let i = index">
            <div class="contenedor">
              <div class="hijo2">
                <div class="CounterWrapper-sc-__sc-z8zey9-2 dLbZJp">
                  <div class="SpacerWrapper-sc-__sc-4rs8xl-0 gOWBGu">
                    <div *ngIf="steps.tipo === 'input'" id="header-counter-7b8c2c13cf9e115f" 
                      class="CounterContent-sc-__sc-z8zey9-0 PBZLo"><!--Tipo inpunt-->
                      <span data-qa="question-header-counter" class="TextWrapper-sc-__sc-1f8vz90-0 bFxsfa"><span
                          aria-hidden="true">{{i+1}}</span></span><span aria-hidden="true"
                        class="Boundary-sc-__sc-184gmm6-0 cHaqfv"><svg height="10" width="11">
                          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z">
                          </path>
                          <path d="M8 4v2H0V4z"></path>
                        </svg></span><span class="hZUeEQ">{{steps.label}} <br>
                        {{steps.descripcion}}
                        <br></span><br>

                      <input id="fieldsSave[steps.id]" type="text" name="fieldsSave[steps.id]" [(ngModel)]="fieldsSave[steps.id]"
                        aria-labelledby="short_text-7b8c2c13cf9e115f-G2xlq3tOC47BRGif-title" tabindex="0 "
                        autocomplete="off" placeholder="Escribe aquí tu respuesta..."
                        class="InputField-sc-__sc-26uh88-0 dKicNu" value=""><br><br>
                    </div>
                    <div *ngIf="steps.tipo === 'textarea'" id="header-counter-7b8c2c13cf9e115f"
                      class="CounterContent-sc-__sc-z8zey9-0 PBZLo"><!--Textarea-->
                      <span data-qa="question-header-counter" class="TextWrapper-sc-__sc-1f8vz90-0 bFxsfa"><span
                          aria-hidden="true">{{i+1}}</span></span><span aria-hidden="true"
                        class="Boundary-sc-__sc-184gmm6-0 cHaqfv"><svg height="10" width="11">
                          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z">
                          </path>
                          <path d="M8 4v2H0V4z"></path>
                        </svg></span><span class="hZUeEQ">{{steps.label}} <br>
                        {{steps.descripcion}}
                        <br></span><br>
                      <mat-form-field class="example-full-width" appearance="fill">
                        <textarea matInput placeholder="Tu respuesta aquí.." 
                        [(ngModel)]="fieldsSave[steps.id]"
                        name="{{ steps.id }}"></textarea>
                      </mat-form-field>
                    </div>
                    <div *ngIf="steps.tipo === 'radio'" id="header-counter-7b8c2c13cf9e115f"
                      class="CounterContent-sc-__sc-z8zey9-0 PBZLo"><!--Tipo radio-->
                      <!-- Radio -->
                      <span data-qa="question-header-counter" class="TextWrapper-sc-__sc-1f8vz90-0 bFxsfa"><span
                          aria-hidden="true">{{i+1}}</span></span><span aria-hidden="true"
                        class="Boundary-sc-__sc-184gmm6-0 cHaqfv"><svg height="10" width="11">
                          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z">
                          </path>
                          <path d="M8 4v2H0V4z"></path>
                        </svg></span><span class="hZUeEQ">{{steps.label}} <br>
                        {{steps.descripcion}}
                        <br></span><br>

                        <mat-radio-group
                        aria-labelledby="example-radio-group-label"
                        class="example-radio-group"
                        [(ngModel)]="fieldsSave[steps.id]"
                      >
                        <mat-radio-button
                          class="example-radio-button"
                          *ngFor="let season of seasons"
                          [value]="season"
                        >
                          {{ season }}
                        </mat-radio-button>
                      </mat-radio-group><br><br>
                    </div>
                    <div *ngIf="steps.tipo === 'checkboxList'" id="header-counter-7b8c2c13cf9e115f"
                      class="CounterContent-sc-__sc-z8zey9-0 PBZLo"><!--checkboxlist-->
                      <span data-qa="question-header-counter" class="TextWrapper-sc-__sc-1f8vz90-0 bFxsfa"><span
                          aria-hidden="true">{{i+1}}</span></span><span aria-hidden="true"
                        class="Boundary-sc-__sc-184gmm6-0 cHaqfv"><svg height="10" width="11">
                          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z">
                          </path>
                          <path d="M8 4v2H0V4z"></path>
                        </svg></span><span class="hZUeEQ">{{steps.label}} <br>
                        {{steps.descripcion}}
                        <br></span><br>
                        <section class="example-section" [formGroup]="fieldsSave[steps.id]">
                          <div class="ch" *ngFor="let arr of steps.value; let i = index" style="margin-bottom: 15px;">
                          <mat-checkbox style="white-space: break-spaces;"
                          formControlName="{{ cleanUnnecessaryWhiteSpaces(arr) }}" 
                          id="check_{{ cleanUnnecessaryWhiteSpaces(arr) }}">
                          {{ arr }}
                        </mat-checkbox>
                      </div>
                        </section>
                      <br><br>
                    </div>
                    <div *ngIf="steps.tipo === 'customList'" id="header-counter-7b8c2c13cf9e115f"
                      class="CounterContent-sc-__sc-z8zey9-0 PBZLo"><!--Customlist--> 
                      <span data-qa="question-header-counter" class="TextWrapper-sc-__sc-1f8vz90-0 bFxsfa"><span
                          aria-hidden="true">{{i+1}}</span></span><span aria-hidden="true"
                        class="Boundary-sc-__sc-184gmm6-0 cHaqfv"><svg height="10" width="11">
                          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z">
                          </path>
                          <path d="M8 4v2H0V4z"></path>
                        </svg></span><span class="hZUeEQ">{{steps.label}} <br>
                        {{steps.descripcion}}
                        <br></span><br>

                      <input type="text" matInput class="css-input" [formControl]="myControl" [(ngModel)]="fieldsSave[steps.id]"
                        [matAutocomplete]="auto" />
                      <mat-autocomplete #auto="matAutocomplete" class="css-textarea" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{ option.name }}
                        </mat-option>
                      </mat-autocomplete><br><br>
                    </div>
                  </div>

                </div>
                <br><br>
                <button data-qa="ok-button-visible deep-purple-ok-button-visible" tabindex="0"
                  class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 kcBXtr"><span
                    class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"><span
                      class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"><span
                        class="TextWrapper-sc-__sc-1f8vz90-0 kULmuD">Aceptar</span></span><span
                      class="Spacer-sc-__sc-1fl5ef-0 zWQvE"><span class="IconWrapper-sc-__sc-1qu8p4z-5 PvHzy"><span
                          class="Boundary-sc-__sc-184gmm6-0 hyaMLH"><svg height="13" width="16">
                            <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z">
                            </path>
                          </svg></span></span></span></span></button>
                <div aria-hidden="true" class="HelperWrapper-sc-__sc-1ra8r4w-1 eGsDJf">
                  <div class="ActionHelperWrapper-sc-__sc-1qx1n1u-0 grkOHx">
                    <div class="KeyTextWrapper-sc-__sc-1qx1n1u-1 uCrVC">
                      <div class="TextWrapper-sc-__sc-1f8vz90-0 dtbavj">pulsa <strong>Enter ↵</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="contenedor">
              <div class="hijo2">
                <div class="ct1">
                  <br><br>
                  <div class="myButton" (click)="proceder()">PROCEDER</div>

                 <br><br>
              </div>
            </div>
            </div>
          </li>
          <li>
            <div class="contenedor">
              <div class="hijo2">
                <div class="ct1">
                  <img height="100px" width="100px" src="assets/images/success-svgrepo-com.svg" />
                  <br><span class="title_success">Completado</span><br>
                  Su consulta ha sido aperturada correctamente<br><br>

                  <svg height="13" width="16">
                    <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z">
                    </path>
                  </svg> En revisión por equipo partners<br>
                  <svg height="13" width="16">
                    <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z">
                    </path>
                  </svg> Pronto recibira comentarios preliminares<br><br>

                  Puede ver novedades de esta consulta en la sección mi consulta desde la App o nuestro Portal
                  Web.
                </div>

              </div>
            </div>
          </li>
        </ul>
        <div class="verticalCarouselHeader">
          <h3></h3>
          <span class="vc_goDown"><i class="fa fa-fw fa-angle-down btn"></i></span>
          <span class="vc_goUp"><i class="fa fa-fw fa-angle-up btn"></i></span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>