import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Messages } from './data';
import { Message } from './chat.model';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { HttpClient } from '@angular/common/http';

import { format, isToday } from 'date-fns';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';  // If using Firebase storage
import 'firebase/database'; // If using Firebase database
import { DatePipe } from '@angular/common';
import { PublicService } from 'src/app/public.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-themechat',
  templateUrl: './themechat.component.html',
  styleUrls: ['./themechat.component.scss'],
  providers:[DatePipe]
})

/**
 * Chat-component
 */
export class ThemeChatComponent implements OnInit {

  activetab = 2;
  Messages: Message[];

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  lang: string;

  @ViewChild('chatcontent') chatcontent: ElementRef;
  scrolltop: number = null;
  @Input () id: string;

  chatForm: UntypedFormGroup;
  nickname = '';
  roomname = '';
  message = '';
  users = [];
  chats = [];
  matcher = new MyErrorStateMatcher();

constructor(public publicService: PublicService, private authFackservice: AuthfakeauthenticationService, private authService: AuthenticationService,
  private router: Router, private http: HttpClient, private modalService: NgbModal,
  private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public datepipe: DatePipe) {
  }

  ngOnInit(): void {
    this.cargarChat();

    this.Messages = Messages;
    this.chatForm = this.formBuilder.group({
      'message' : [null, Validators.required]
    });
    this.publicService.get_perfil_users_profile().subscribe(data => {
      console.log(data);
      this.nickname = data.profile_user.nombre +' '+ data.profile_user.apellidos;
   },error => console.log(error));
  }

  cargarChat() {
      this.roomname = this.id;
      console.log(this.roomname);
      firebase.database().ref('chats/'+this.roomname).on('value', resp => {
        this.chats = [];
        this.chats = snapshotToArray(resp);
        console.log(this.chats);
        setTimeout(() => {
          document.getElementById('ventanachat').scrollTop=document.getElementById('ventanachat').scrollHeight;
        },200);
      });
      firebase.database().ref('roomusers/').orderByChild('roomname').equalTo(this.roomname).on('value', (resp2: any) => {
        const roomusers = snapshotToArray(resp2);
        this.users = roomusers.filter(x => x.status === 'online');

      });
  }

  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'fackbackend') {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }


  openCallModal(content) {
    this.modalService.open(content, { centered: true });
  }


  openVideoModal(content) {
    this.modalService.open(content, { centered: true });
  }

  onFormSubmit(form: any) {
    const chat = form;
    chat.roomname = this.roomname;
    chat.nickname = this.nickname;
    chat.date = new Date().toISOString();
    console.log(new Date().toISOString());
    chat.type = 'message';
    const newMessage = firebase.database().ref('chats/'+this.roomname).push();
    newMessage.set(chat);
    this.chatForm = this.formBuilder.group({
      'message' : ['', Validators.required]
    });
  }

  exitChat() {
    const chat = { roomname: '', nickname: '', message: '', date: '', type: '' };
    chat.roomname = this.roomname;
    chat.nickname = this.nickname;
    chat.date = this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
    chat.message = `${this.nickname} leave the room`;
    chat.type = 'exit';
    const newMessage = firebase.database().ref('chats/').push();
    newMessage.set(chat);

    firebase.database().ref('roomusers/').orderByChild('roomname').equalTo(this.roomname).on('value', (resp: any) => {
      let roomuser = [];
      roomuser = snapshotToArray(resp);
      const user = roomuser.find(x => x.nickname === this.nickname);
      if (user !== undefined) {
        const userRef = firebase.database().ref('roomusers/' + user.key);
        userRef.update({status: 'offline'});
      }
    });

    this.router.navigate(['/roomlist']);
  }

  esHoy(fecha) {
    return isToday(new Date(fecha));
  }


  formatoFecha(fecha, f) {
    return new Date(fecha);
  }

}
