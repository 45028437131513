import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import authgear, { Page } from '@authgear/web';
import { UserService } from 'src/app/services/user.service';
import { PublicService } from 'src/app/public.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showModal: boolean = true;
  filteredOptions: Observable<any[]>;
  checkoutOption: string;
  convenioOption: string = 'dani';
  item1: boolean = false;
  item2: boolean = false;
  item3: boolean = false;
  sub;

  constructor(private modalService: NgbModal, public user: UserService, public publicService: PublicService, public authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.updateGreetingMessage().catch((e) => {
      console.log(e);
    });
    document.getElementById('btn').click();
    window.scrollTo(0, 0);
    setTimeout(()=> {
      (<any> $('.verticalCarousel')).verticalCarousel({
        currentItem: 1,
        showItems: 1,
        totalItems: 5
      });
    }, 1500);
  }

  openGroupModal(info: any) {
    this.modalService.open(info, { centered: true , size: 'xl'});
  }

  async updateGreetingMessage() {
        const userInfo = await authgear.fetchUserInfo();
        this.publicService.get_perfil_users_profile().subscribe(data => {
          console.log(data.profile_user.email, data.id_authguard);
          this.authenticationService.login(data.profile_user.email, data.id_authguard);
       },error => console.log(error));
  }

}
