<div *ngIf="seccion === 'home'" #div class="{{scrollable}}">
  <div id="header" style="background: linear-gradient(
  rgba(0, 0, 0, 0.3),
  rgba(0, 0, 0, 0.3)
), url({{detalle.imagen}}) no-repeat center center / cover;">
    <textarea id="id1" style="display: none">{{detalle.imagen}}</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarDetail()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left"
                width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Start profile content -->
  <div class="p-3" id="contenedor-main">
    <div class="titulo" (click)="abrirApertura()">{{detalle.nombre}}</div>
    <div class="row">
      <div class="column">Precio: {{detalle.precio_stripe}}$</div>
      <div class="column">+ {{detalle.consultas_realizadas}} consultas realizadas</div>
    </div>
  </div>
  <div class="cuadro">
    <div class="tipos"><br>LA PRACTICA<br></div>
    <h5>DESCRIPCIÓN</h5>
    <div class="cg-title">      <span [innerHTML]="detalle.descripcion"></span>
      </div>
    <div class="row">
      <div class="column"></div>
    </div>
  </div>
  <div class="cuadro1">
    <div class="tipos"><br>¿COMO FUNCIONA<br></div>
    <h5>ESTE SERVICIO DE ASESORÍA/PRODUCTO?</h5>
    <div class="cg-title01"> {{detalle.como_funciona}}<br>
    </div>
  </div>
  <div class="cuadro">
    <div class="tipos"><br>CONSEJOS<br></div>
    <h5>DURANTE LA ASESORÍA</h5>
    <div class="cg-title01">
      <span [innerText]="detalle.consejos"></span>

       <br>
    </div>
  </div>
  <div class="cuadro1">
    <div class="tipos"><br>PRDOCUTOS<br></div>
    <h5>LEGALES RELACIONADOS</h5>
    <div class="cg-title01">
      <span [innerText]="detalle.productos_relacionados"></span>

       <br>
    </div>
  </div>
  <div class="cuadro">
    <div class="tipos"><br>PREGUNTAS<br></div>
    <h5>FRECUENTES</h5>
    <div class="cg-title01">
      <button type="button" class="btn btn-process">
       <span [innerText]="detalle.preguntas_frecuentes"></span></button><br>
    </div>
    <span (click)="abrirApertura(detalle.id)" class="myButton2">SOLICITAR CONSULTORÍA AHORA</span>
    <div class="ccenter">o</div>
    <span (click)="openGroupModal(content)" class="myButton1">HACER UNA PREGUNTA RAPIDA </span>

  </div>
  <div class="tipos"><br>DESPACHOS &<br></div>
  <h5>ABOGADOS</h5>
  <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" interval="20000" [keyboard]="true"
  [pauseOnHover]="true" [wrap]="true">
  <ng-container *ngFor="let descriprequerida of lawyers">
    <ng-template ngbSlide id="{{descriprequerida.id}}">
      <section>
        <div class="col-xs-12 col-sm-12">
          <div class="row">
            <div class="column">
              <div class="card" style="width: 100%;">
                <img class="centradoi" src="{{descriprequerida.profile_lawyer.logo}}" img>
                <h3>{{descriprequerida.profile_lawyer.nombre}} </h3>
                <p style="color:#74838587">{{descriprequerida.profile_lawyer.servicios}} </p>
                <div class="star-rating">
                  <a>&#9733;</a>
                  <a>&#9733;</a>
                  <a>&#9733;</a>
                  <a>&#9733;</a>
                  <a>&#9733;</a>
                </div>
                <p style="color:#74838587"> {{descriprequerida.profile_lawyer.sedes}} </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>
  </ng-container>
</ngb-carousel>

  <div>
    <div class="tipos"><br>ASESORIAS<br></div>
    <h5>SIMILARES</h5>
    <ul class="comma-list">
      <li *ngFor="let item of tendencias" (click)="abrirDetalle(item.id)">
        {{ item.nombre }}
      </li>
    </ul>
  </div>
</div>
     <!-- Start add group Modal -->
     <ng-template #content let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
            Añadir nueva pregunta rapida</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
          </button>
        </div>
        <div class="modal-body p-4">
          <form>
            <div class="mb-4">
              <label class="form-label" for="addgroupname-input">Ingrese la pregunta</label><span style="font-size: 13px;;">(Máximo 80 caracteres permitidos)</span>
              <input type="text" class="form-control" [(ngModel)]="pregunta" maxlength="80" id="addgroupname-input"
                placeholder=""><span style="font-size: 13px;;">{{pregunta.length}} caracteres</span>
            </div>
            <div class="form-group">
              <label class="form-label"
                for="addgroupdescription-input">Descripción</label><span style="font-size: 13px;;">(Máximo 200 caracteres)</span>
              <textarea class="form-control" [(ngModel)]="descripcion" id="addgroupdescription-input" maxlength="200" rows="3"
                placeholder=""></textarea><span style="font-size: 13px;;">{{descripcion.length}} caracteres</span>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
          <button type="button" (click)="createQuestion(pregunta, descripcion)"
            class="btn">Crear pregunta</button>
        </div>
      </div>
    </ng-template>
    <!-- End add group Modal -->
<ng-container #apertura></ng-container>
