<div class="p-0" *ngIf="seccion === 'home'">
  <div id="header_c">
      <div class="weather-card one">
          <div class="top">
              <div class="wrapper">
                <div class="mynav">
                  <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                    <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
                    </svg></a>
                    <span class="title">Perfil</span>
                </div>
              </div>
            </div>
        </div>
  </div>
  <div>
<div class="user-profile-sidebar" id="profile-detail">
  <div class="px-3 px-lg-4 pt-3 pt-lg-4">
    <div class="user-chat-nav text-end">
    </div>
  </div>

  <div class="text-center p-1 border-bottom">
    <div class="mb-4">
      <img src="assets/images/users/avatar-4.jpg" class="rounded-circle avatar-lg img-thumbnail" alt="">
    </div>
    <h5 class="font-size-16 mb-1 text-truncate">Daniel Rodríguez</h5>
    <p class="text-muted text-truncate mb-1"><i class="ri-record-circle-fill font-size-10 text-success me-1"></i>
      Activo</p>
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-1 user-profile-desc">
    <div class="text-muted">
      <p class="mb-4"> Mi perfil</p>
    </div>
    <div *ngIf="bandera1 ===  true" >
    <form id="contactForm" [formGroup]="formProfile" (ngSubmit)="submit()">

    <div id="profile-user-accordion" class="custom-accordion">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Información personal">
              <div>
                <mat-card class="contact-card" style="width:100%">
                  <mat-card-content>
                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Nombre" maxlength="25" formControlName="nombre" class="form-control"
                        name="nombre">
                    </mat-form-field>
                    <br>
                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Apellidos" maxlength="35" formControlName="apellidos" class="form-control" name="apellidos">
                    </mat-form-field>
                    <br>
                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Ciudad" maxlength="25" formControlName="ciudad" class="form-control" name="ciudad">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Logo" maxlength="45" formControlName="logo" class="form-control" name="logo">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Ocupación" maxlength="55" formControlName="ocupacion" class="form-control" name="ocupacion">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="País" maxlength="45" formControlName="pais" class="form-control" name="pais">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Teléfono" maxlength="10" formControlName="telefono" class="form-control" name="telefono">
                    </mat-form-field>
                    <br>
                  </mat-card-content>
                </mat-card>

              </div>
        </mat-tab>
        <mat-tab label="Información fiscal">              <div>
          <mat-tab-group mat-align-tabs="center">
            <mat-tab label="GENERAL">
              <mat-card class="contact-card" style="width:100%">
                <mat-card-content>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Nombre Legal" maxlength="65" formControlName="nombre_legal" class="form-control"
                      name="nombre_legal">
                  </mat-form-field>
                  <br>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Email para recibir facturas" maxlength="35" formControlName="email_fiscal"
                      class="form-control" name="email_fiscal">
                  </mat-form-field>
                  <br>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="RFC" formControlName="rfc" maxlength="13" class="form-control" name="rfc">
                  </mat-form-field>
                  <br>
                </mat-card-content>
              </mat-card>
            </mat-tab>
            <mat-tab label="DOMICILIO">
              <mat-card class="contact-card" style="width:100%">
                <mat-card-content>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Código Postal" formControlName="cp" maxlength="5" class="form-control"
                      name="cp">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Ciudad" formControlName="ciudad_fiscal" maxlength="35" class="form-control"
                      name="ciudad_fiscal">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Estado" formControlName="estado" maxlength="25" class="form-control"
                      name="estado">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Calle" formControlName="calle" maxlength="20" class="form-control" name="calle">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Exterior" formControlName="ext" maxlength="10" class="form-control" name="ext">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Interior" formControlName="int" maxlength="10" class="form-control" name="int">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Municipio" formControlName="municipio" maxlength="35" class="form-control"
                      name="municipio">
                  </mat-form-field>
                  <br>
                  <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Colonia" formControlName="colonia" maxlength="25" class="form-control"
                      name="colonia">
                  </mat-form-field>
                  <br>
                </mat-card-content>
              </mat-card>
            </mat-tab>
          </mat-tab-group>

        </div></mat-tab>
      </mat-tab-group>
      <!-- End Attached Files card -->
    </div>
    <mat-dialog-actions align="end">
      <button id="btnSubmit" mat-raised-button type="submit" color="primary" cdkFocusInitial [disabled]="submitted" class="button load btn">Entrar
        <i *ngIf="btnLock === 1" class="fa fa-lock loader"></i>
        <i *ngIf="btnLock === 2" class="fa fa-circle-o-notch fa-spin loader"></i>
        <i *ngIf="btnLock === 3" class="fa fa-unlock loader"></i></button>
      <button id="btnClose" [mat-dialog-close]="response" style="display: none;" mat-raised-button
        color="primary">Cerrar</button>
    </mat-dialog-actions>
  </form>
</div>
    <!-- end profile-user-accordion -->
  </perfect-scrollbar>
  <!-- end user-profile-desc -->
</div>
</div>

<ng-container #contenido></ng-container>
