import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, Inject, ElementRef, ComponentFactoryResolver } from '@angular/core';
import { SeguimientoConsultaComponent } from './../seguimiento/seguimiento.component';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase/app';
import 'firebase/storage';  // If using Firebase storage
import 'firebase/database'; // If using Firebase database
import 'firebase/auth';
import 'firebase/firestore';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ReviewsComponent } from '../../reviews/reviews.component';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { PublicService } from 'src/app/public.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})

/**
 * Tabs-Profile component
 */
export class ConsultaComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrar: EventEmitter<string> = new EventEmitter();
  listPracticas: any;
  practicaTitulo: string;
  @Input() consultaId: string;
  private _ref;
  scrollable = 'scrollable1';
  @ViewChild('div', { static: true }) _div: ElementRef;
  activo = '1';
  seccion = 'home';
  error;
  baseUrl: string = environment.api;
  cards;
  sat;
  private scrollChangeCallback: () => void;

  descripcion;
  imagenId = 'https://cuartocastejo.com/legal/fiscal.png';
  consulta: any;
  ancho = window.innerWidth;
  alto = window.innerHeight;
  number = 134;
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
  comision;
  info_fiscal;

  listComentarios: any;
  innerWidth;
  idCaso: any;

  idChat: any;

  constructor(public publicService: PublicService, private factoryResolver: ComponentFactoryResolver, public authenticationService: AuthenticationService, public dialog: MatDialog, private http: HttpClient, public authFackservice: AuthfakeauthenticationService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (sessionStorage.getItem('token')) {
      this.scrollable = 'scrollable';
    }
    if (sessionStorage.getItem('token')) {
      this.authenticationService.login('dani@gmail.com', sessionStorage.getItem('token'));
    }
    this.innerWidth = window.innerWidth;
    this.cargarMiConsulta(this.consultaId);
  }

  ver(lawyer) {
    const dialogRef = this.dialog.open(ReviewsComponent, {
      data: {
        despacho: lawyer,
      },
    });
  }

  solicitarConsulta(idLawyer, lawyer, comentario): void {
    const dialogRef = this.dialog.open(ConfirmarPagoDialog, {
      data: {
        precio: comentario.precio.precioStripe.precio,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.avanzar) {
        delete result.avanzar;
        const room = 'LBP_' + idLawyer + this.generateUUID();
        let requestCaso = {
          'titulo': this.consulta.titulo ? this.consulta.titulo : '',
          'consulta': this.consulta.id,
          'localidad': this.consulta.informacion_apertura.ciudad,
          'precio': this.consulta.presupuesto,
          'resena': this.consulta.consulta_desc.toUpperCase(),
          'checklist': this.consulta.consulta.checklist,
          'documentacion': this.consulta.consulta.evidencias,
          'room': room,
          'email': lawyer.email,
          'lawyer': idLawyer,
          'informacion_apertura': this.consulta.informacion_apertura,
          'status': 'APERTURA'
        };
        const newRoom = firebase.database().ref('rooms/').push();
        newRoom.set({ roomname: room });
        var date = new Date();
        var dateStr =
          ('00' + date.getDate()).slice(-2) + '/' +
          ('00' + (date.getMonth() + 1)).slice(-2) + '/' +
          date.getFullYear() + ' ' +
          ('00' + date.getHours()).slice(-2) + ':' +
          ('00' + date.getMinutes()).slice(-2) + ':' +
          ('00' + date.getSeconds()).slice(-2);
        let requestChat = {
          'idConsulta': this.consulta.id,
          'partner': idLawyer,
          'lawyer': idLawyer,
          'nombre_despacho': '',
          'nombre_room': room,
          'avatar': '',
          'tipo_asesoria': this.consulta.consulta_desc.toUpperCase(),
          'fecha_apertura': dateStr,
          'status': 'APERTURA'
        };
        const source1: Observable<any> = this.http.post(this.baseUrl + '/private/casoslawyers', requestCaso);
        const source2: Observable<any> = this.http.post(this.baseUrl + '/private/chats', requestChat);
        const sources: Observable<any>[] = [source1, source2];
        forkJoin(sources).subscribe((response: any[]) => {
          const value1: any = response[0];
          const value2: any = response[1];
          console.log(lawyer);
          console.log(value1, value2);
          let request = {
            'acct_id': lawyer.stripe_id,
            'monto': comentario.precio.precioStripe.id,//PriceIDSrtipe
            'comision': Math.floor(comentario.precio.comisionCC), //  this.comision,//Comision calculada previamente
            'sat': this.sat,
            'desc': this.descripcion,
            'idChat': value2.id,
            'idCaso': value1.id,
            'comentario': comentario.id,
            'f': result.customer.facturar,
            'c': result.customerId,
            'u': result.idUsuario
          };
          console.log(request);
          this.publicService.post_generar_cargo(request).subscribe(res => {
            let body: any = res;
            window.location.href = body.url;
          }, error => console.log(error));
        });
      }
    });
  }


  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  cargarMiConsulta(id): void {
    this.publicService.get_misconsultas(id).subscribe(data => {
      console.log('CCCCCC', data);
      this.consulta = data;
      this.cargarTipodeCaso(this.consulta.tipo_de_caso);
      this.cargarComentarios();
    }, error => this.error = error);
  }

  cargarTipodeCaso(idtipoCaso): void {
    this.publicService.get_tiposdecasos(idtipoCaso).subscribe(data => {
      //INVOCAR AL SERVICIO prívate/statusproductousuario/03_PENAL_001
      //Retoma la bandera de gratuitidad, precio prefrenecial o regular
      this.descripcion = data.nombre;
      this.sat = data.sat;
      this.comision = data.cobro_comision;
    }, error => this.error = error);
  }

  cargarComentarios(): void {
    this.publicService.get_comentarios(this.consulta.id).subscribe(data => {
      console.log(data.items);
      this.listComentarios = data.items;
    }, error => this.error = error);
  }

  cerrarPractica(): void {
    this.cerrar.emit('true');
  }

  abrirSeguimiento(id): void {
    this.seccion = 'inicio';


    const factory = this.factoryResolver.resolveComponentFactory(SeguimientoConsultaComponent);
    this._ref = this.detalle.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
      this.detalle.clear();
      this.seccion = 'home';
    });
  }




  formatString(str) {
    // Replace "_" and ":" with a space
    str = str.replace(/_|:/g, ' ');

    // Convert the first letter to uppercase
    str = str.charAt(0).toUpperCase() + str.slice(1);

    return str;
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
  }

}

@Component({
  selector: 'confirmar-pago',
  styles: ['.example-radio-group {display: flex;flex-direction: unset;margin: 15px 0;align-items: flex-start;}', '.example-radio-button {margin: 5px;}'],
  template: `<h2 mat-dialog-title style="text-align: center;"><b>¿Desea solicitar esta consultoria?</b></h2>
  <mat-dialog-content class="mat-typography" style="text-align: center;">
    <h3>El costo de esta asesoria es de {{data.precio}} $MXN, le recomendamos revisar el aviso de privacidad y los acuerdos de contratación antes de procesar su pago electronico. [Ver aviso de privacidad].</h3>
  </mat-dialog-content>
  <div style="text-align: center; margin-bottom: 25px;">¿Desea facturar su consulta?</div>
  <mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="payment">
  <mat-radio-button class="example-radio-button" style="display: inline-block; margin-right: 10px;" *ngFor="let option of options" [value]="option.value">
  {{option.label}}
  </mat-radio-button>
</mat-radio-group>
<form id="contactForm" [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
  <div *ngIf="payment === true">
    <h2 mat-dialog-title style="text-align: center;"><b>Información fiscal</b></h2>
    <mat-tab-group mat-align-tabs="center">
  <mat-tab label="GENERAL">
    <mat-card class="contact-card" style="width:100%">
  <mat-card-content >
    <mat-form-field style="width: 100%;">
      <input matInput placeholder="Nombre Legal" formControlName="nombre_legal" class="form-control"  name="nombre_legal" autocomplete="off">
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%;">
      <input matInput placeholder="Email para recibir facturas" formControlName="email_fiscal" class="form-control"  name="email_fiscal" autocomplete="off">    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%;">
    <input matInput placeholder="RFC" formControlName="rfc" class="form-control"  name="rfc" autocomplete="off">
    </mat-form-field>
    <br>
  </mat-card-content>
</mat-card>
</mat-tab>
  <mat-tab label="DOMICILIO">
  <mat-card class="contact-card" style="width:100%">
  <mat-card-content >
  <div class="container">
    <br><br>
  <div class="row">
    <div class="col-4 col-sm-4"><mat-form-field style="width: 100%;">
      <input matInput placeholder="Código Postal" formControlName="cp" class="form-control" autocomplete="off"  name="cp">
    </mat-form-field>
    <br></div>
    <div class="col-4 col-sm-4"><mat-form-field style="width: 100%;">
      <input matInput placeholder="Ciudad" formControlName="ciudad" class="form-control" autocomplete="off"  name="ciudad">
    </mat-form-field>
    <br></div>
    <!-- Force next columns to break to new line at md breakpoint and up -->
    <div class="col-4 col-sm-4"><mat-form-field style="width: 100%;">
    <input matInput placeholder="Estado" formControlName="estado" class="form-control" autocomplete="off"  name="estado">
    </mat-form-field>
    <br></div>
    <div class="col-4 col-sm-4"><mat-form-field style="width: 100%;">
    <input matInput placeholder="Calle" formControlName="calle" class="form-control" autocomplete="off"  name="calle">
    </mat-form-field>
    <br></div>
    <div class="col-4 col-sm-4">
    <mat-form-field style="width: 100%;">
    <input matInput placeholder="Exterior" formControlName="ext" class="form-control" autocomplete="off"  name="ext">
    </mat-form-field>
    <br></div>
    <div class="col-4 col-sm-4"><mat-form-field style="width: 100%;">
    <input matInput placeholder="Interior" formControlName="int" class="form-control" autocomplete="off"  name="int">
    </mat-form-field>
    <br></div>
    <div class="w-100 d-none d-md-block"></div>
    <div class="col-6 col-sm-6">
    <mat-form-field style="width: 100%;">
    <input matInput placeholder="Municipio" formControlName="municipio" class="form-control" autocomplete="off"  name="municipio">
    </mat-form-field>
    <br></div>
    <div class="col-6 col-sm-6"><mat-form-field style="width: 100%;">
    <input matInput placeholder="Colonia" formControlName="colonia" class="form-control" autocomplete="off"  name="colonia">
    </mat-form-field>
    <br></div>
  </div>
</div>
  </mat-card-content>
</mat-card>
  </mat-tab>
  </mat-tab-group>

  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button id="btnSubmit"  mat-raised-button type="submit" color="primary" cdkFocusInitial>Iniciar</button>
    <button id="btnClose" [mat-dialog-close]="response" style="display: none;" mat-raised-button  color="primary">Cerrar</button>
  </mat-dialog-actions>
</form>`,
})

export class ConfirmarPagoDialog {
  options = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
  error;
  payment = false;
  form;
  info_fiscal;
  user;
  idUser;
  customerId;
  response;
  public dialog: MatDialogRef<any>;
  @ViewChild('namedElement', { static: false }) namedElement: ElementRef;
  scrollChangeCallback: () => void;

  constructor(public publicService: PublicService, @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.cargarInfoProfile();
  }

  cargarInfoProfile(): void {
    this.publicService.get_perfil_users_profile().subscribe(data => {
      this.info_fiscal = data.profile_user ? data.profile_user.customer : null;
      this.user = data.profile_user;
      this.customerId = data.profile_user ? data.profile_user.customerId : null;
      this.idUser = data.id;
      this.form = this.formBuilder.group({
        nombre_legal: [this.info_fiscal?.legal_name ? this.info_fiscal.legal_name : '', Validators.required],
        email_fiscal: [this.info_fiscal?.email ? this.info_fiscal?.email : '', Validators.required],
        rfc: [this.info_fiscal?.tax_id ? this.info_fiscal?.tax_id : '', [Validators.required]],
        cp: [this.info_fiscal?.address?.zip ? this.info_fiscal?.address.zip : '', Validators.required],
        ciudad_fiscal: [this.info_fiscal?.address?.city ? this.info_fiscal?.address.city : '', Validators.required],
        estado: [this.info_fiscal?.address?.state ? this.info_fiscal?.address.state : '', Validators.required],
        calle: [this.info_fiscal?.address?.street ? this.info_fiscal?.address.street : '', Validators.required],
        ext: [this.info_fiscal?.address?.exterior ? this.info_fiscal?.address.exterior : '', Validators.required],
        int: [this.info_fiscal?.address?.interior ? this.info_fiscal?.address.interior : '', Validators.required],
        municipio: [this.info_fiscal?.address?.municipality ? this.info_fiscal?.address.municipality : '', Validators.required],
        colonia: [this.info_fiscal?.address?.neighborhood ? this.info_fiscal?.address.neighborhood : '', Validators.required]
      });
    }, error => this.error = error);
  }

  submit() {
    this.response = {
      'avanzar': true,
      'idUsuario': this.idUser,
      'email': this.user ? this.user.email : '',
      'nombre': this.user ? this.user.nombre : '',
      'apellidos': this.user ? this.user.apellidos : '',
      'ocupacion': this.user ? this.user.ocupacion : '',
      'ciudad': this.user ? this.user.state : '',
      'pais': this.user ? this.user.pais : '',
      'logo': this.user ? this.user.logo : '',
      'customerId': this.customerId ? this.customerId : '',
      'customer': {
        'tax_id': this.form.value.rfc,
        'email': this.form.value.email_fiscal,
        'address': {
          'zip': this.form.value.cp,
          'city': this.form.value.estado,
          'state': this.form.value.estado,
          'street': this.form.value.calle,
          'country': 'MEX',
          'exterior': this.form.value.ext,
          'interior': this.form.value.int,
          'municipality': this.form.value.municipio,
          'neighborhood': this.form.value.colonia
        },
        'facturar': this.payment,
        'legal_name': this.form.value.nombre_legal,
        'tax_system': '601'
      },
      'telefono': this.user ? this.user.telefono : ''
    };
    this.publicService.patch_perfil_users(this.idUser, this.response).subscribe(data => {
      setTimeout(() => { document.getElementById('btnClose').click() }, 1000);
    }, error => this.error = error);

  }

}


export interface DialogData { }

function firebaseConfig(firebaseConfig: any) {
  throw new Error('Function not implemented.');
}
