import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, ElementRef  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contacts } from './misconsultas.model';
import { ConsultaComponent } from './consulta/consulta.component';
import { HttpClient } from '@angular/common/http'; // <----
import { PublicService } from 'src/app/public.service';
@Component({
  selector: 'app-misconsultas',
  templateUrl: './misconsultas.component.html',
  styleUrls: ['./misconsultas.component.scss']
})

export class ConsultasComponent implements OnInit {

  contacts: Contacts[];
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  contactsList: any;
  private _ref;
  load = true;
  consultas: any;
  error;
  imagenId= 'https://cuartocastejo.com/legal/fiscal.png';
  @ViewChild('div', {static: true}) _div: ElementRef;
  private scrollChangeCallback: () => void;
  listComentarios: any;
  innerWidth;
  seccion = 'home';
  scrollable = 'scrollable1';



  constructor(public publicService: PublicService, private modalService: NgbModal, private factoryResolver: ComponentFactoryResolver, private http: HttpClient) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (sessionStorage.getItem('token')) {
      this.scrollable = 'scrollable';
    }
    this.load = false;
    this.innerWidth = window.innerWidth;
    this.cargarMisConsultas();
  }

  cargarMisConsultas(): void {
    this.publicService.get_consulta().subscribe(data => {
      this.consultas = data.items;
   },error => this.error = error);
  }

  /*cargarComentarios(): void {
    this.publicService.get_comentarios(this.consulta.id).subscribe(data => {
      this.listComentarios = data.items;
    }, error => this.error = error);
  }*/

  openContactsModal(content) {
    this.modalService.open(content, { centered: true });
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(ConsultaComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.consultaId = id;
    this._ref.instance.cerrar.subscribe(data => {
        this.practica.clear();
        this.seccion = 'home';
    });
  }

  formatString(str) {
    // Replace "_" and ":" with a space
    str = str.replace(/_|:/g, ' ');

    // Convert the first letter to uppercase
    str = str.charAt(0).toUpperCase() + str.slice(1);

    return str;
  }



  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
  }

}
