import { Component, OnInit, Output, Input, EventEmitter, Inject, ElementRef, Renderer2, AfterViewChecked, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http'; // <----
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import 'jquery';
import { PublicService } from 'src/app/public.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
/**
 * Tabs-Profile component
 */
export class AperturaComponent implements OnInit, AfterViewInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrarDetalle: EventEmitter<string> = new EventEmitter();
  seccion = 'home';
  motivo: any = '';
  descripcion: any = '';
  evidencias: any = '';
  etapa: any = '';
  involucrados: any = '';
  contraparte: any = '';
  demandas: any = '';
  configuracion: any;
  tipoCaso: any;
  ciudad: any;
  checklist: UntypedFormGroup;
  @Input() apertura: string;
  error;
  myControl = new UntypedFormControl();
  options: any[] = [{ name: 'CDMX' }, { name: 'NUEVO LEON' }, { name: 'PUEBLA' }, { name: 'JALISCO' }, { name: 'EDO_MEX' }, { name: 'TAMPICO' }];
  filteredOptions: Observable<any[]>;
  favoriteSeason: string;
  seasons: string[] = ['Si', 'No'];
  fieldsSave;
  progressVal = '0';

  constructor(private _formBuilder: UntypedFormBuilder, private publicService: PublicService, fb: UntypedFormBuilder, private http: HttpClient, public dialog: MatDialog, private router: Router, private elementRef: ElementRef, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    this.checklist = fb.group({
      check1: false,
      check2: false,
      check3: false,
      check4: false
    });
  }



  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(()=> {
      (<any> $('.verticalCarousel')).verticalCarousel({
        currentItem: 1,
        showItems: 1,
        totalItems: 5
      });
    }, 1500);
    this.cargarTipoDeCaso();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.options.slice())),
    );
  }

  ngAfterViewInit()	{

  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  cargarTipoDeCaso(): void {
    console.log(this.apertura);
    this.publicService.get_tiposdecasos(this.apertura).subscribe(data => {
      this.tipoCaso = data;
      this.configuracion = data.configuracion_formulario;
      this.fieldsSave = {};
      for (let x of this.configuracion) {
        // for (let y of x.fields) {
          if (x.id === 'checkbox_list01') {
            let obj = {};
            for (var i = 0; i < x.value.length; i++) {
              obj[x.value[i].replace(/ /g, '_')] = false;
            }
            console.log(obj);
            console.log(x.id);
            this.fieldsSave[x.id] = this._formBuilder.group(obj);
          } else if (x.id === 'checkbox_list02') {
            let obj = {};
            for (var i = 0; i < x.value.length; i++) {
              obj[x.value[i].replace(/ /g, '_')] = false;
            }
            this.fieldsSave[x.id] = this._formBuilder.group(obj);
          } else if (x.id === 'checkbox_list03') {
            let obj = {};
            for (var i = 0; i < x.value.length; i++) {
              obj[x.value[i].replace(/ /g, '_')] = false;
            }
            this.fieldsSave[x.id] = this._formBuilder.group(obj);
          } else if (x.id === 'checkbox_list04') {
            let obj = {};
            for (var i = 0; i < x.value.length; i++) {
              obj[x.value[i].replace(/ /g, '_')] = false;
            }
            this.fieldsSave[x.id] = this._formBuilder.group(obj);
          } else if (x.id === 'checkbox_list05') {
            let obj = {};
            for (var i = 0; i < x.value.length; i++) {
              obj[x.value[i].replace(/ /g, '_')] = false;
            }
            this.fieldsSave[x.id] = this._formBuilder.group(obj);
          } else {
            this.fieldsSave[x.id] = '';
          }
          console.log(this.fieldsSave);
       // }
      }
      console.log(this.fieldsSave);
    }, error => this.error = error);
  }

  cleanUnnecessaryWhiteSpaces(cadena: string) {
    return cadena.replace(/ /g, '_');
  }

  cerrarApertura(): void {
    this.cerrarDetalle.emit('true');
  }

  proceder(): void {
    this.fieldsSave.checkbox_list01 = this.fieldsSave.checkbox_list01 ? this.fieldsSave.checkbox_list01.value : null;
    this.fieldsSave.checkbox_list02 = this.fieldsSave.checkbox_list02 ? this.fieldsSave.checkbox_list02.value : null;
    this.fieldsSave.checkbox_list03 = this.fieldsSave.checkbox_list03 ? this.fieldsSave.checkbox_list03.value : null;
    this.fieldsSave.checkbox_list04 = this.fieldsSave.checkbox_list04 ? this.fieldsSave.checkbox_list04.value : null;
    this.fieldsSave.ciudad = this.fieldsSave.ciudad ? this.fieldsSave.ciudad.name : null;
    const dialogRef = this.dialog.open(ConfirmarAperturaDialog);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let request = {
          'idTipodeCaso': this.apertura,
          'motivo': this.motivo,
          'descripcion': this.descripcion,
          'checklist': [],
          'evidencias': this.evidencias,
          'etapa_del_proceso': this.etapa,
          'involucrados': this.involucrados,
          'info_contraparte': this.contraparte,
          'demandas_solicitadas': this.demandas,
          'configuracion_formulario': this.fieldsSave,
          'tipo_apertura': this.tipoCaso.prod_comercial
        };
        this.publicService.post_abrir_consulta(request).subscribe(res => {
          let id: any = res;
          let request2 = {
            'titulo': this.tipoCaso.nombre.toUpperCase(),
            'estatus': 'Apertura',
            'presupuesto': this.tipoCaso.precio_stripe,
            'precio_id_stripe': this.tipoCaso.precio,
            'tipo_de_caso': this.tipoCaso.id,
            'ciudad': this.fieldsSave.ciudad,
            'expedientes': 'Ninguno',
            'consulta': id.id,
            'comentarios': 'La consulta ha sido aperturada',
            'consulta_desc': this.tipoCaso.tag + ':' + this.tipoCaso.nombre,
            'informacion_apertura': this.fieldsSave,
            'tipo_apertura':
              this.tipoCaso.prod_comercial
          };
          this.publicService.post_misconsultas(request2).subscribe(response => {
            window.location.reload();
          }, error => console.log(error));
        }, error => console.log(error));
      }




    });
  }
}

@Component({
  selector: 'confirmar-apertura',
  template: `<div style="padding:50px;"><h2 style="font-size: 13px;">¿Desea aperturar este caso?</h2>
  <mat-dialog-content class="mat-typography">
    <h3>Una vez aperturado el caso sera dirigido al inicio, podra darle seguimiento en la sección mis consultas</h3>
    <h3>Los abogados de inmediato revisaran su caso y haran comentarios preliminares, a fin de que pueda elegir con quien trabajar correctamente.</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Iniciar</button>
  </mat-dialog-actions></div>`,
})
export class ConfirmarAperturaDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}

export interface DialogData { }
