import { Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-comunidad',
  templateUrl: './comunidad.component.html',
  styleUrls: ['./comunidad.component.scss']
})
export class ComunidadComponent implements OnInit {

  mensajes;
  seccion = 'home';

  message;
  nickname;
  @Output () cerrar: EventEmitter<string> = new EventEmitter();

  constructor(private publicService: PublicService, private router: Router) { }

  ngOnInit(): void {
    //Carga stipos de soporte
    this.publicService.get_community().subscribe(data => {
      this.mensajes = data.items;
    }, error => console.log(error));
    this.publicService.get_perfil_users_profile().subscribe(data => {
      this.nickname = data.profile_user.nombre;
    }, error => console.log(error));
  }

  altaMensaje(req) {
    //Carga stipos de soporte
    let obj = {
      "author": this.nickname,
      "avatar": "",
      "mensaje": req
    };
    this.publicService.set_community(obj).subscribe(data => {
      this.mensajes = data.items;
      // save current route first
      const currentRoute = this.router.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
    }, error => console.log(error));
  }

  cerrarDetail(): void {
    this.cerrar.emit('true');
  }

}
