import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PracticaComponent } from './practica/practica.component';
import { PublicService } from 'src/app/public.service';
import { DetalleComponent } from './detalle/detalle.component';

@Component({
  selector: 'app-abrirconsulta',
  templateUrl: './abrirconsulta.component.html',
  styleUrls: ['./abrirconsulta.component.scss']
})

export class AbrirConsultaComponent implements OnInit {

  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
  @Output() cerrar: EventEmitter<string> = new EventEmitter();

  practicaId = 'fiscal';
  private _ref;
  private _ref2;
  getParam;
  components = [];
  practicas;
  productos;
  legal;
  estrategica;
  y;
  colorToggle = 'primary';
  backgroundColorToggle = 'primary';
  reclamaciones;

  error;
  load = true;

  constructor(private dataService: PublicService, private actRoute: ActivatedRoute, private factoryResolver: ComponentFactoryResolver) {
    this.getParam = this.actRoute.snapshot.params.idPractica ? this.actRoute.snapshot.params.idPractica : 0;
  }

  abrirReclamaciones(id): void {
  }

  abrirProductos(id): void {
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(PracticaComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
      this.practica.clear();
      this.seccion = 'home';
    });
  }

  abrirDetalle(id): void {
    console.log(id);
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(DetalleComponent);
    this._ref2 = this.detalle.createComponent(factory);

    this._ref2.instance.detalleId = id;
    this._ref2.instance.cerrarDetalle.subscribe(data => {
      this.detalle.clear();
      this.seccion = 'home';
    });
  }



  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.cargarPracticas();
    this.cargarProductos();
    this.cargarReclamaciones();
    this.cargarEstrategica();
    this.cargarLegal();
  }

  cargarPracticas(): void {
    this.dataService.get_practicas().subscribe((data: any) => {
      let arr = [];
      for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].tag !== 'productos' && data.items[i].tag !== 'reclamaciones_indemnizaciones') {
          arr.push(data.items[i]);
        }
      }
      this.practicas = arr;
      this.load = false;
    }, error => this.error = error);
  }

  cargarLegal(): void {
    this.dataService.get_cargar_consultoria_legal().subscribe((data: any) => {
      this.legal = data.items;
      this.load = false;
    }, error => this.error = error);
  }

  cargarEstrategica(): void {
    this.dataService.get_cargar_consultoria_estrategica().subscribe((data: any) => {
      this.estrategica = data.items;
      this.load = false;
    }, error => this.error = error);
  }

  cargarProductos(): void {
    this.dataService.get_cargar_productos().subscribe((data: any) => {
      this.productos = data.items;
      this.load = false;
    }, error => this.error = error);
  }

  cargarReclamaciones(): void {
    this.dataService.get_cargar_reclamaciones().subscribe((data: any) => {
      this.reclamaciones = data.items;
      this.load = false;
    }, error => this.error = error);
  }

  ngAfterViewInit(): void {

    // setTimeout(function () {
    if (+this.getParam !== 0) {
      this.abrirPractica(this.getParam);
    }
    // }, 1000);
  }

  parentOnScroll(event: any) {
    window.onscroll = function () {
      var y = window.scrollY;
      this.y = y;
    };
  }

}
