<!-- Start chats content -->
<div *ngIf="seccion === 'home'">
  <div id="header_c">
    <div class="weather-card one">
        <div class="top">
            <div class="wrapper">
              <div class="mynav">
                <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                  <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
                  </svg></a>
                  <span class="title">Salas de chat</span>
              </div>
            </div>
          </div>
      </div>
</div>
  <div class="px-4 pt-4">
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
        <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
          <i class="ri-search-line search-icon font-size-18"></i>
        </span>
        <input type="text" class="form-control bg-light"
          placeholder="Buscar mensajes o usuarios" aria-label="Search messages or users"
          aria-describedby="basic-addon1">
          <button mat-raised-button>Buscar</button>


      </div>
    </div>
  </div> <!-- .p-4 -->

  <!-- Start chat-message-list -->
  <div class="px-4">
    <h5 class="mb-3 px-3 font-size-16">Chats recientes</h5>

    <perfect-scrollbar class="chat-message-list">

      <ul class="list-unstyled chat-list chat-user-list">
        <li *ngFor="let user of chat"
        style="padding-bottom: 12px;"
          (click)="abrirConsulta(user.idConsulta)">
          <span>
            <div class="d-flex">
              <div class="chat-user-img align-self-center me-3 ms-0">
                <div class="avatar-xs" >
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                    {{(user.partner.profile_lawyer.nombre ).charAt(0)}} {{(user.partner.profile_lawyer.nombre ).split(" ")[1].charAt(0)}}
                  </span>
                </div>
                <span *ngIf="user.status" class="user-status"></span>
              </div>

              <div class="flex-1 overflow-hidden">
                <h5 class="text-truncate mb-1" style="font-size: 13px;;">{{user.partner.profile_lawyer.nombre }}</h5>
                <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">typing<span
                    class="animate-typing">
                    <span class="dot ml-1"></span>
                    <span class="dot ml-1"></span>
                    <span class="dot ml-1"></span>
                  </span></p>
                <p class="chat-user-message text-truncate mb-0 " style="font-size: 13px;;">{{formatString(user.tipo_asesoria | lowercase) }}</p>
              </div>
              <div class="font-size-11">{{user.fecha_apertura }}</div>
              <div class="unread-message">
                <span class="badge badge-soft-danger rounded-pill">{{user.unRead}}</span>
              </div>
            </div>
          </span>
        </li>
      </ul>
    </perfect-scrollbar>

  </div>
  <!-- End chat-message-list -->
</div>
<!-- Start chats content -->
<ng-container #detalle></ng-container>
