<div class="p-0" *ngIf="seccion === 'home'">
    <div id="header_c">
        <div class="weather-card one">
            <div class="top">
                <div class="wrapper">
                  <div class="mynav">
                    <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                      <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
                      </svg></a>
                      <span class="title">Facturas/Recibos</span>
                  </div>
                </div>
              </div>
          </div>
    </div>
    <mat-tab-group>
        <mat-tab label="FACTURAS">
            <table mat-table [dataSource]="dataFacturas" style="width: 100%;" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef> Monto / Creación </th>
                  <td mat-cell *matCellDef="let element"> {{element.monto | currency}} / {{element.creacion  }} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="estatus">
                  <th mat-header-cell *matHeaderCellDef> Estatus </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="caMcNh" *ngIf="element.estatus === 'TIMBRADO'">
                      Válida
                    </span>
                    <span  class="badget-red"*ngIf="element.estatus === 'ERROR'">Error</span>
                  </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-raised-button style="float:right;" [disabled]="element.estatus === 'canceled' ? true : false" (click)="abrirAccionesFactura(element.acciones)">Acciones</button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </mat-tab>
        <mat-tab label="RECIBOS">
          <br><h6>Aquí podrá visualizar sus recibos no facturados, si requiere facturar algun recibo contactenos a  <span class="badge bg-dark">contacto@cuartocastejo.com</span><br><br>
          Para poder ayudarlo.</h6>
          <br>
            <table mat-table [dataSource]="dataRecibos" style="width: 100%;" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef> Monto / Creación </th>
                  <td mat-cell *matCellDef="let element"> {{element.monto | currency}} / {{element.creacion | slice:0:10 }} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="estatus">
                  <th mat-header-cell *matHeaderCellDef> Estatus </th>
                  <td mat-cell *matCellDef="let element"> {{element.estatus}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-raised-button style="float:right;">No disponible</button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </mat-tab>
      </mat-tab-group>

</div>
<ng-container #contenido></ng-container>
