import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; // <----
import { ConsultaComponent } from '../misconsultas/consulta/consulta.component';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
/**
 * Tabs-settings component
 */
export class ProductosComponent implements OnInit {

  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  practicaId = 'fiscal';
  private _ref;
  components = [];
  load = true;
  imagenId= 'https://cuartocastejo.com/legal/fiscal.png';
  productos;
  error;

  constructor(public publicService: PublicService, private actRoute: ActivatedRoute, private factoryResolver: ComponentFactoryResolver, private http: HttpClient)
    { }

  ngOnInit(): void {
    this.load = false;
    this.cargarMisConsultas();
  }

  cargarMisConsultas(): void {
    this.publicService.get_private_productos().subscribe(data => {
      this.productos = data.items;
   },error => this.error = error);
  }


  abrirPractica(id): void {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(ConsultaComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.consultaId = id;
    this._ref.instance.cerrar.subscribe(data => {
        this.practica.clear();
        this.seccion = 'home';
    });
  }


}
