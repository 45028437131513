<div *ngIf="seccion === 'home'">
  <div id="header" >
    <textarea id="id1" style="display: none">https://cuartocastejo.com/legal/fiscal.png</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
              <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
              </svg></a>
            <a href="">
        <svg class="lnr-cog" fill="red" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
      </a>
          </div>
          <div id="header-contenido">
          <h1 class="heading">Reclamaciones e indemnizaciones</h1>
          <div class="mynav01">
            <div class="lnr-chevron-left01">{{reclaims.length}}<br>reclamos</div>
          </div>
          </div>
        </div>
      </div>
      </div>
  </div>
<div class="contenedor01">
<div _ngcontent-bpd-c217="">
    <ul _ngcontent-bpd-c217="" class="verti-timeline list-unstyled">
        <li _ngcontent-bpd-c217="" class="event-list" *ngFor="let consulta of reclaims">
            <div _ngcontent-bpd-c217="" class="event-timeline-dot"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11.999 1.993c-5.514.001-10 4.487-10 10.001s4.486 10 10.001 10c5.513 0 9.999-4.486 10-10c0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8s3.589-8 8-8.001C16.411 3.994 20 7.583 20 11.994c-.001 4.411-3.59 8-8 8z"/><path fill="currentColor" d="M12 10.994H8v2h4V16l4.005-4.005L12 7.991z"/></svg>
            </div>
            <div _ngcontent-bpd-c217="" class="media">
                <div _ngcontent-bpd-c217="" class="me-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#000" d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"/><path fill="#000" d="M6 12h6v2H6zm0 4h6v2H6z"/></svg>
                </div>
                <div _ngcontent-bpd-c217="" class="media-body">
                    <div _ngcontent-bpd-c217="">
                        <h5 _ngcontent-bpd-c217="" class="font-size-14">
                            <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Practica: {{consulta.consulta_desc  | lowercase }},</font></font> {{consulta.createdAt | date: 'dd/MM/yyyy hh:mm'}}
                        </h5>
                        <p _ngcontent-bpd-c217="" class="text-muted">
                            <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{consulta.informacion_apertura.descripcion_general}}</font></font>
                            <br><a (click)="abrirPractica(consulta.id)" class="button">Leer más</a>
                        </p>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
</div>
</div>
<ng-container #practica></ng-container>
