<div class="p-0" *ngIf="seccion === 'home'">
  <div id="header_c">
      <div class="weather-card one">
          <div class="top">
              <div class="wrapper">
                <div class="mynav">
                  <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                    <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
                    </svg></a>
                    <span class="title">Preguntas rapidas</span>
                </div>
              </div>
            </div>
        </div>
  </div>
   <!-- Start Groups content -->
 <div  class="p-4">
   <div>
     <div class="user-chat-nav float-end">
       <div ngbTooltip="Create group">
         <!-- Button trigger modal -->
         <button type="button" class="btn btn-link text-decoration-none text-muted font-size-30 py-0"
           data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openGroupModal(content)">
           Añadir pregunta
           <i class="ri-file-add-line"></i>
         </button>
       </div>
     </div>
     <h4 class="mb-4">Realiza una pregunta sencilla de manera gratuita.</h4>

     <!-- Start add group Modal -->
     <ng-template #info let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
            {{infoPregunta.motivo}}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
          </button>
        </div>
        <div style="margin-left: 16px;">{{infoPregunta.descripcion}}</div>

        </div>
        <div class="modal-body p-4">
          <mat-card-content *ngFor="let item of respuestas; let i = index;" _ngcontent-jmg-c374="" class="mat-card-content">
            <div _ngcontent-jmg-c374="" class="timeline">
                <div _ngcontent-jmg-c374="" class="timeline-item">
                    <div _ngcontent-jmg-c374="" class="timeline-badge"><img _ngcontent-jmg-c374="" src="{{item.avatar}}" alt="" /></div>
                    <div _ngcontent-jmg-c374="" class="timeline-body">
                        <div _ngcontent-jmg-c374="" fxlayout="row" class="timeline-body-top" style="flex-direction: row; box-sizing: border-box; display: flex;">
                            <a _ngcontent-jmg-c374="" href="#" class="timeline-body-title mr-16"><b _ngcontent-jmg-c374="">{{item.author}}</b> comento hace poco  </a> <br><span style="margin-left: 5px;" _ngcontent-jmg-c374="" class="text-muted"> {{item.createdAt |  date: 'dd/MM/yyyy hh:mm'}}</span>
                            <span _ngcontent-jmg-c374="" fxflex="" style="flex: 1 1 0%; box-sizing: border-box;"></span>
                        </div>
                        <div _ngcontent-jmg-c374="" class="timeline-body-content">
                            <p _ngcontent-jmg-c374="">{{item.respuesta}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>

        </div>
    </ng-template>

     <!-- Start add group Modal -->
     <ng-template #content let-modal>
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
             Añadir nueva pregunta rapida</h5>
           <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
             (click)="modal.dismiss('Cross click')">
           </button>
         </div>
         <div class="modal-body p-4">
           <form>
             <div class="mb-4">
               <label class="form-label" for="addgroupname-input">Ingrese la pregunta</label><span style="font-size: 13px;;">(Máximo 80 caracteres permitidos)</span>
               <input type="text" class="form-control" [(ngModel)]="pregunta" maxlength="80" id="addgroupname-input"
                 placeholder=""><span style="font-size: 13px;;">{{pregunta.length}} caracteres</span>
             </div>
             <div class="form-group">
               <label class="form-label"
                 for="addgroupdescription-input">Descripción</label><span style="font-size: 13px;;">(Máximo 200 caracteres)</span>
               <textarea class="form-control" [(ngModel)]="descripcion" id="addgroupdescription-input" maxlength="200" rows="3"
                 placeholder=""></textarea><span style="font-size: 13px;;">{{descripcion.length}} caracteres</span>
             </div>
           </form>
         </div>
         <div class="modal-footer">
           <button type="button" class="btn btn-link" data-dismiss="modal"
             (click)="modal.dismiss('Cross click')">Cerrar</button>
           <button type="button" (click)="createQuestion(pregunta, descripcion)"
             class="btn">Crear pregunta</button>
         </div>
       </div>
     </ng-template>
     <!-- End add group Modal -->

     <!-- end search-box -->
   </div>
   <!-- Start chat-group-list -->
   <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>
     <ul class="list-unstyled chat-list">
       <li *ngFor="let item of preguntasList; let i = index;" (click)="openGroupModal(info, item)" class="space">
           <div class="d-flex align-items-center">
             <div class="flex-1 overflow-hidden">
               <h5 class="font-size-14 mb-0">{{i+1}}.- {{item.motivo}}
                 <span class="badge badge-soft-danger rounded-pill float-end">0</span>
               </h5>
               {{item.descripcion}} [Ver detalle]

             </div>
           </div>
       </li>
     </ul>
   </perfect-scrollbar>
   <!-- End chat-group-list -->
 </div>
 <!-- End Groups content -->
</div>
<ng-container #contenido></ng-container>
