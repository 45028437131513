<div class="layout-wrapper d-lg-flex">
  <div class="side-menu flex-lg-column me-lg-1">
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <span class="logo-sm" style="color: white; font-size: 13px;;">
          <br>CUARTOCASTEJO<br>
      </span><span style="font-size: 11px;; color: white;">Socios legales y estrategicos</span>

      <a routerLink="/" class="logo logo-light">
        <span class="logo-sm">
          <img src="assets/images/logolb.png" alt="" height="30" />
        </span>
      </a>
    </div>
    <!-- end navbar-brand-box -->

    <!-- Start side-menu nav -->
    <div class="flex-lg-column" class="topL" style="margin-bottom: 200px">
      <ul
        class="nav nav-pills side-menu-nav justify-content-left"
        role="tablist"
      >
        <li class="nav-item" ngbTooltip="Solicitar consulta">
          <span
            class="nav-link"
            id="pills-user-tab"
            (click)="removeTab(); activeTab(1)"
            [ngClass]="{ active: activetab === 1 }"
          >
            <i class="ri-play-list-add-line view"></i>
            <span style="font-size: 13px; color: white" class="view1"
              >Aperturar</span
            >
          </span>
        </li>
        <br />
        <li class="nav-item" ngbTooltip="Chats">
          <span
            class="nav-link active"
            id="pills-chat-tab"
            (click)="removeTab(); activeTab(2)"
            [ngClass]="{ active: activetab === 2 }"
          >
            <i class="ri-chat-2-line view"></i>
            <span style="font-size: 13px; color: white" class="view1">Chats</span>
          </span>
        </li>
        <br />
        <li class="nav-item" ngbTooltip="Servicios" placement="top">
          <span
            class="nav-link"
            id="pills-contacts-tab"
            (click)="removeTab(); activeTab(3)"
            [ngClass]="{ active: activetab === 3 }"
          >
          <i class="ri-briefcase-2-fill view"></i>
          <span style="font-size: 13px; color: white" class="view1"
              >Mis Consultas</span
            >
          </span>
        </li>
        <br />
        <li class="nav-item" ngbTooltip="Servicios" placement="top">
          <span
            class="nav-link"
            id="pills-contacts-tab"
            (click)="removeTab(); activeTab(4)"
            [ngClass]="{ active: activetab === 4 }"
          >
          <i class="ri-question-answer-fill view"></i>
          <span style="font-size: 13px; color: white" class="view1"
              >Preguntas rapidas</span
            >
          </span>
        </li>
        <br />
        <li class="nav-item" ngbTooltip="Servicios" placement="top">
          <span
            class="nav-link"
            id="pills-contacts-tab"
            (click)="removeTab(); activeTab(5)"
            [ngClass]="{ active: activetab === 5 }"
          >
          <i class="ri-product-hunt-fill view"></i>
          <span style="font-size: 13px; color: white" class="view1"
              >Productos</span
            >
          </span>
        </li>
        <br />
        <li class="nav-item" ngbTooltip="Servicios" placement="top">
          <span
            class="nav-link"
            id="pills-contacts-tab"
            (click)="removeTab(); activeTab(6)"
            [ngClass]="{ active: activetab === 6 }"
          >
          <i class="ri-service-fill view"></i>
          <span style="font-size: 13px; color: white" class="view1"
              >Reclamaciones</span
            >
          </span>
        </li>
        <br />
        <li class="nav-item" ngbTooltip="Facturas/Recibos">
          <span
            class="nav-link"
            id="pills-setting-tab"
            (click)="removeTab(); activeTab(8); this.consultas.clear()"
            [ngClass]="{ active: activetab === 8 }"
          >
          <i class="ri-bilibili-fill view"></i>
          <span style="font-size: 13px; color: white" class="view1"
              >Facturas/Recibos</span
            >
          </span>
        </li>

        <li
          class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none"
          ngbDropdown
          placement="top"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <img
              src="assets/images/users/avatar-1.jpg"
              alt=""
              class="profile-user rounded-circle"
            />
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            Perfil<i class="ri-profile-line float-end text-muted"></i>
            <a class="dropdown-item" href="#"
              >Soporte<i
                class="ri-settings-3-line float-end text-muted"
              ></i
            ></a>
            <div class="dropdown-divider"></div>
            <span class="dropdown-item" (click)="logout()"
              >Salir<i class="ri-logout-circle-r-line float-end text-muted"></i
            ></span>
          </div>
        </li>
      </ul>
    </div>
    <!-- end side-menu nav -->

    <div class="flex-lg-column d-none d-lg-block">
      <ul class="nav side-menu-nav justify-content-center">
        <li
          class="nav-item btn-group dropup profile-user-dropdown"
          ngbDropdown
          placement="top-left"
        >
          <span>
            <img
              src="assets/images/users/avatar-1.jpg"
              alt=""
              class="profile-user rounded-circle"
            />
          </span>
        </li>
        <ul
          class="nav nav-pills side-menu-nav justify-content-left"
          role="tablist"
        >
          <li class="nav-item" ngbTooltip="Solicitar consulta">
            <span
              class="nav-link"
              id="pills-user-tab"
              (click)="removeTab(); activeTab(9)"
              [ngClass]="{ active: activetab === 9 }"
            >
              <!-- <i class="ri-play-list-add-line"></i>  -->
              <span style="font-size: 13px; color: white"
                >Perfil</span
              >
            </span>
          </li>
          <li class="nav-item" ngbTooltip="Solicitar consulta">
            <span
              class="nav-link"
              id="pills-user-tab"
              (click)="removeTab(); activeTab(10)"
              [ngClass]="{ active: activetab === 10 }"
            >
              <!-- <i class="ri-play-list-add-line"></i>  -->
              <span style="font-size: 13px; color: white"
                >Soporte</span
              >
            </span>
          </li>
          <li class="nav-item" ngbTooltip="Solicitar consulta">
            <span class="nav-link" id="pills-user-tab" (click)="logout()">
              <!-- <i class="ri-play-list-add-line"></i>  -->
              <span style="font-size: 13px; color: white"
                >Salir</span
              >
            </span>
          </li>
        </ul>
      </ul>
    </div>
    <!-- Side menu user -->
  </div>
  <!-- start chat-leftsidebar -->
  <div style="margin-left: 164px;">
    <ng-container #home></ng-container>

  </div>

  <div
    class="chat-leftsidebar me-lg-1"
  >
    <div class="tab-content">
      <!-- Start Profile tab-pane -->
      <div id="pills-user" role="tabpanel" aria-labelledby="pills-user-tab">
        <ng-container #consultas class="slide-left-ctn"></ng-container>
      </div>
    </div>
  </div>
  <!-- Start User chat -->


</div>
