import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, Inject } from '@angular/core';
import { InformacionComponent } from './informacion/informacion.component';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { PublicService } from 'src/app/public.service';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ComunidadComponent } from './comunidad/comunidad.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.scss']
})
export class SoporteComponent implements OnInit {
  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  private _ref;
  formGroup: UntypedFormGroup;
  post;
  soportes;
  btnLock = 1;
  submitted = false;
  response;

  dataRecibos;
  motivos: any[] = [
    { value: 'Facturación', viewValue: 'Facturación' },
    { value: 'Seguridad', viewValue: 'Seguridad' },
    { value: 'Administración de cuentas', viewValue: 'Administración de cuentas' },
    { value: 'Características generales de la plataforma', viewValue: 'Características generales de la plataforma' },
    { value: 'Calidad de servicio', viewValue: 'Calidad de servicio' }
  ];
  displayedColumns: string[] = ['motivo', 'acciones'];
  error: any;

  constructor(private publicService: PublicService,  public dialog: MatDialog, private factoryResolver: ComponentFactoryResolver, private formBuilder: UntypedFormBuilder, private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      field: ['', Validators.required],
      asunto: ['', [Validators.required]],
      descripcion: ['', [Validators.required]]
    });
    //Carga recibos
    this.publicService.get_tickets().subscribe(data => {
      const dataS = data.items;
      const arrRecibos = [];
      for (const row of dataS) {
        arrRecibos.push({ motivo: row.motivo, descripcion: row.descripcion, acciones: row.id });
      }

      this.dataRecibos = arrRecibos;
      console.log(this.dataRecibos);
    }, error => console.log(error));
    //Carga stipos de soporte
    this.publicService.get_soporte().subscribe(data => {
      const dataSoporte = data.items;
      this.soportes = dataSoporte;
    }, error => console.log(error));
  }

  onSubmit(post) {
    this.post = post;
    console.log(post);
    const d = this.dialog.open(ConfirmAlert, {
      width: '320px',
      data: { isTrue: true },
    });
    d.afterClosed().subscribe(result => {
      if (result) {
        this.submitted = true;
        this.btnLock = 2;
        this.response = {
          'tipo_soporte': this.post.field,
          'motivo': this.post.asunto,
          'descripcion': this.post.descripcion
        };
        console.log(this.response);
        this.publicService.post_ticket(this.response).subscribe(data => {
          window.location.reload();
        }, error => this.error = error);

        setTimeout(() => { document.getElementById('btnClose').click() }, 1000);
      }
    });
  }

  abrirDetalle(idTicket): void {
    console.log(idTicket)
    this._bottomSheet.open(DetalleTicket, { data: { id: idTicket}});
  }

  cargarDetalle(id): void {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(InformacionComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
      this.practica.clear();
      this.seccion = 'home';
    });
  }

  GoCommunity() {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(ComunidadComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = 'Comunidad';
    this._ref.instance.cerrar.subscribe(data => {
      this.practica.clear();
      this.seccion = 'home';
    });
  }

}


@Component({
  selector: 'ConfirmAlert',
  template: `<h1 mat-dialog-title>¿Desea levantar este ticket?</h1>
  <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Gracias</button>
  <button mat-button [mat-dialog-close]="data.isTrue" cdkFocusInitial>Si</button>
</div>`,
})
export class ConfirmAlert {
  constructor(public dialogRef: MatDialogRef<ConfirmAlert>, @Inject(MAT_DIALOG_DATA) public data: DialogD,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogD {
  isTrue: boolean;
}


@Component({
  selector: 'DetalleTicket',
  template: `<h1>Detalle del ticket</h1>
  <div class="card-white" style="width: 100%;">
  <div class="card-body">
    <h5 class="card-title">{{ticket.motivo}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">Categoría: {{ticket.tipo_soporte}}</h6>
    <p class="card-text">{{ticket.descripcion}}</p>
    <h1>Respuestas</h1>

  </div>
  <p *ngIf="responses.length === 0" class="card-text" style="text-align:center;">No hay respuestas aun.</p>
  <div class="card-body" *ngFor="let response of responses">
  <h5 class="card-title">Autor: {{response.author}}</h5>
  <h6 class="card-subtitle mb-2 text-muted">Fecha: {{response.createdAt | date: 'dd/MM/yyyy hh:mm'}}</h6>
  <p class="card-text">{{response.mensaje}}</p>
</div>
</div>
  `,
  styleUrls: ['./soporte.component.scss']
})
export class DetalleTicket {
  private _bottomSheetRef: any;
  ticket;
  idTicket;
  responses;

  constructor(public publicService: PublicService, public dialog: MatDialog, @Inject(MAT_BOTTOM_SHEET_DATA) public data: {id: string[]}) {
    this.idTicket = data.id;
  }

  ngOnInit() {
    //Carga stipos de soporte
    this.publicService.get_ticket(this.idTicket).subscribe(data => {
      console.log(data);
      this.ticket = data;
    }, error => console.log(error));

    this.publicService.get_ticket_response(this.idTicket).subscribe(data => {
      console.log(data);
      this.responses = data.items;
    }, error => console.log(error));
  }


  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

}
