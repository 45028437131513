import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { PublicService } from 'src/app/public.service';
import { DialogData } from '../misconsultas/consulta/consulta.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  despacho;
  colorToggle = 'primary';
  backgroundColorToggle = 'primary';
  rating3;
  reviews: any;
  comentario;
  username;

  constructor(public publicService: PublicService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private http: HttpClient) {
    this.rating3 = 0;
  }

  ngOnInit(): void {
    this.despacho = this.data;
    this.cargarUser();
    this.cargarReviews();
  }

  cargarUser() {
    this.publicService.get_perfil_users_profile().subscribe(res => {
      let respuesta: any = res;
      this.username = respuesta.username;
    }, error => console.log(error));
  }

  cargarReviews() {
    this.publicService.get_reviews(String(this.despacho.despacho.lawyer.id)).subscribe(res => {
      let data:any = res;
      this.reviews = data.items;
    }, error => console.log(error));
  }

  altaReviews() {
    let request =  {
      'comentario': this.comentario,
      'escrito_por': this.username,
      'ranking': +this.rating3,
      'despacho': this.despacho.despacho.lawyer.id
  };
    this.publicService.post_reviews(request).subscribe(res => {
      this.cargarReviews();
    }, error => console.log(error));
  }

}
