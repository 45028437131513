import {
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appHighlight]',
})
export class HighlightDirective implements OnInit, OnChanges {
  public intial = false;
  @Input()
  htmlString: string;
  constructor(private el: ElementRef) {
    this.el.nativeElement.style.backgroundColor = 'white';
  }

  ngOnChanges(changes) {
    console.log('ngOnChanges ---->');
    console.log(changes, 'changes are not dectected');
    this.changeColor();
  }

  ngOnInit() {
    console.log('ngOnInit ---->');
    this.intial = true;
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    this.changeColor();
  }

  changeColor() {
    this.el.nativeElement.style.backgroundColor = 'white';
  }
}
