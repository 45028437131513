<div class="p-0" *ngIf="seccion === 'home'">
    <div id="header_c">
        <div class="weather-card one">
            <div class="top">
                <div class="wrapper">
                    <div class="mynav">
                        <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15"
                                height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                                enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <g>
                                    <path
                                        d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                                    <path
                                        d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                                    <path
                                        d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                                </g>
                            </svg></a>
                        <span class="title">Soporte</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-tab-group>
        <mat-tab label="Recursos">
            <div style="padding:10px;">
                <div style="font-size: 13px;;">
                    <br>Bienvenido al soporte de <b>Cuartocastejo</b>
                </div>
                <br>Seleccione un tema, luego elija una categoria y le mostraremos las preguntas y respuestas más
                frecuentes.<br><br>

                ¿Buscas más ayuda? Pregunte a la comunidad o cree un ticket para enviarlo a la mejor persona para
                responderlo.
                <mat-grid-list cols="3" rowHeight="200px">
                    <mat-grid-tile *ngFor="let soporte of soportes" (click)="cargarDetalle(soporte.id)">
                        <div class="cube">
                            <br><br>
                            <svg *ngIf="soporte.nombre === 'Facturación'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M2 9.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5.5a2.5 2.5 0 1 0 0-5zm2-1.532a4.5 4.5 0 0 1 0 8.064V19h16v-2.968a4.5 4.5 0 0 1 0-8.064V5H4v2.968zM9 9h6v2H9V9zm0 4h6v2H9v-2z"
                                    fill="rgba(83,78,78,1)" />
                            </svg>
                            <svg *ngIf="soporte.nombre === 'Seguridad'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zM5 12v8h14v-8H5zm6 2h2v4h-2v-4zm6-4V9A5 5 0 0 0 7 9v1h10z" />
                            </svg>
                            <svg  *ngIf="soporte.nombre === 'Administración de cuentas'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm2.595 7.812a3.51 3.51 0 0 1 0-1.623l-.992-.573 1-1.732.992.573A3.496 3.496 0 0 1 17 14.645V13.5h2v1.145c.532.158 1.012.44 1.405.812l.992-.573 1 1.732-.992.573a3.51 3.51 0 0 1 0 1.622l.992.573-1 1.732-.992-.573a3.496 3.496 0 0 1-1.405.812V22.5h-2v-1.145a3.496 3.496 0 0 1-1.405-.812l-.992.573-1-1.732.992-.572zM18 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            </svg>
                            <svg  *ngIf="soporte.nombre === 'Caracteristicas generales de la plataforma'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391 1 1.732-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805-1 1.732-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392-1-1.732.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805 1-1.732 1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                            </svg>
                            <svg *ngIf="soporte.nombre === 'Calidad de servicio'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M14.121 10.48a1 1 0 0 0-1.414 0l-.707.706a2 2 0 1 1-2.828-2.828l5.63-5.632a6.5 6.5 0 0 1 6.377 10.568l-2.108 2.135-4.95-4.95zM3.161 4.468a6.503 6.503 0 0 1 8.009-.938L7.757 6.944a4 4 0 0 0 5.513 5.794l.144-.137 4.243 4.242-4.243 4.243a2 2 0 0 1-2.828 0L3.16 13.66a6.5 6.5 0 0 1 0-9.192z" />
                            </svg>
                            <br>{{soporte.nombre}}
                        </div>
                    </mat-grid-tile>

                </mat-grid-list>
                <br><b>Registro de cambios</b><br><br>
                - Se actualizaron lsa imagenes de pila de Cuartocastejo App.<br>
                - Principios de Octubre, integración de contratos y documentos legales.<br>
                - Release de mejoras en consutlas.<br><br><br>
                <div class="center">
                    <b>COMUNIDAD</b><br><br>
                    Involúcrese con una comunidad de expertos apasionados para obtener las respuestas que
                    necesita.<br><br>
                    <input class="hk-button--secondary" (click)="GoCommunity()" type="submit" value="Visite la comunidad de usuarios">
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Mis tickets ({{dataRecibos.length}})">
            Listado de tcikets abiertos<br><br>
            <table mat-table [dataSource]="dataRecibos" style="width: 100%;" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="motivo">
                  <th mat-header-cell *matHeaderCellDef> Motivo </th>
                  <td mat-cell *matCellDef="let element"> {{element.motivo}}</td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-raised-button (click)="abrirDetalle(element.acciones)" style="float:right;">Detalle</button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </mat-tab>
        <mat-tab label="Crear un ticket">
            <div style="padding:10px;">
                <br>Crear nuevo ticket de <b>soporte</b>
                <br>
            </div>
            <div class="container">
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">

                    <mat-form-field appearance="outline" style="width:100%;">
                        <mat-label>¿Sobre que esta relacionado su asunto?</mat-label>
                        <mat-select (selectionChange)="onFieldChanged(i)" formControlName="field">
                            <mat-option *ngFor="let motivo of motivos" [value]="motivo.viewValue">
                                {{motivo.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="form-element">
                        <input matInput placeholder="Motivo" formControlName="asunto" autocomplete="off">
                    </mat-form-field>

                    <mat-form-field class="form-element">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput placeholder="Mencione el motivo del ticket..." formControlName="descripcion"
                            autocomplete="off"></textarea>
                    </mat-form-field>

                    <div class="form-element">
                            <button id="btnSubmit" mat-raised-button type="submit" color="primary" cdkFocusInitial [disabled]="submitted || !formGroup.valid" class="button buttonload btn">Enviar ticket
                                <i *ngIf="btnLock === 1" class="fa fa-lock loader"></i>
                                <i *ngIf="btnLock === 2" class="fa fa-circle-o-notch fa-spin loader"></i>
                                <i *ngIf="btnLock === 3" class="fa fa-unlock loader"></i></button>
                            <button id="btnClose" [mat-dialog-close]="response" style="display: none;" mat-raised-button
                            color="primary">Cerrar</button>
                    </div>

                </form>
            </div>

        </mat-tab>
    </mat-tab-group>

</div>
<ng-container #practica></ng-container>
