<div class="p-2" style="width: 100%">
  <h3>Hola, Daniel!</h3><br>
  <div
    elevation="0"
    spacing="s_32"
    class="sc-ct"
  >
   <div class="cgt">Eventos</div> <br />
    <div class="rec01">
      <div class="gsrt v14Sh OaCVOb" style="width: 36px; height: 64px">
        <div>
          <div class="UIaQzd">23</div>
          <div class="wsnHcb">mar</div>
        </div>
        <div class="WpCYSe">
          <div
            jsname="IcFmi"
            class="VukQLe N2J2Qc"
            data-ved="2ahUKEwi75KzC54yDAxWDJkQIHVkLCRAQocYCegQIMxAC"
          >
            <svg
              class="jUgMbd"
              version="1.1"
              height="25px"
              style="    fill: darkseagreen;"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="ahT01d"
                d="M18,19.1122997 L12,16.5687897 L6,19.1122997 L6,3.94457951 L18,3.94457951 L18,19.1122997 Z M17.7133331,2 L6.28555552,2 C5.02849998,2 4.01142778,3 4.01142778,4.22222222 L4,22 L11.9994443,18.6666667 L19.9988887,22 L19.9988887,4.22222222 C19.9988887,3 18.9703887,2 17.7133331,2 Z"
              ></path>
            </svg>
          </div>
          <div
            jsname="qaNQxf"
            class="VukQLe N2J2Qc A8UiEd"
            style="display: none"
            data-ved="2ahUKEwi75KzC54yDAxWDJkQIHVkLCRAQosYCegQIMxAD"
          >
            <svg
              class="jUgMbd"
              height="25px"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="WsruR"
                d="M17.7133331,2 L6.28555552,2 C5.02849998,2 4.01142778,3 4.01142778,4.22222222 L4,22 L11.9994443,18.6666667 L19.9988887,22 L19.9988887,4.22222222 C19.9988887,3 18.9703887,2 17.7133331,2 Z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="fzHfmf" style="margin-left: 52px;">
        <div class="YOGjf" aria-level="3" role="heading">
          Cita con motivo de su demanda laboral
        </div>
        <div class="SHrHx">
          <div class="cEZxRc">sáb, 8:00 a.m.&thinsp;–&thinsp;11:30 p.m.</div>
          <div class="cEZxRc zvDXNd">
            en Junta de conciliación y arbitraje, Granjas México
          </div>
          <div class="cEZxRc zvDXNd">Ciudad de México, CDMX</div>
        </div>
      </div>

      <div class="i1D74d"></div>
    </div>
  <div class="rec01">
    <div class="gsrt v14Sh OaCVOb" style="width: 36px; height: 64px">
      <div>
        <div class="UIaQzd">23</div>
        <div class="wsnHcb">mar</div>
      </div>
      <div class="WpCYSe">
        <div
          jsname="IcFmi"
          class="VukQLe N2J2Qc"
          data-ved="2ahUKEwi75KzC54yDAxWDJkQIHVkLCRAQocYCegQIMxAC"
        >
          <svg
            class="jUgMbd"
            version="1.1"
            height="25px"
            style="    fill: darkseagreen;"

            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="ahT01d"
              d="M18,19.1122997 L12,16.5687897 L6,19.1122997 L6,3.94457951 L18,3.94457951 L18,19.1122997 Z M17.7133331,2 L6.28555552,2 C5.02849998,2 4.01142778,3 4.01142778,4.22222222 L4,22 L11.9994443,18.6666667 L19.9988887,22 L19.9988887,4.22222222 C19.9988887,3 18.9703887,2 17.7133331,2 Z"
            ></path>
          </svg>
        </div>
        <div
          jsname="qaNQxf"
          class="VukQLe N2J2Qc A8UiEd"
          style="display: none"
          data-ved="2ahUKEwi75KzC54yDAxWDJkQIHVkLCRAQosYCegQIMxAD"
        >
          <svg
            class="jUgMbd"
            height="25px"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="WsruR"
              d="M17.7133331,2 L6.28555552,2 C5.02849998,2 4.01142778,3 4.01142778,4.22222222 L4,22 L11.9994443,18.6666667 L19.9988887,22 L19.9988887,4.22222222 C19.9988887,3 18.9703887,2 17.7133331,2 Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="fzHfmf" style="margin-left: 52px; margin-right: 99px">
      <div class="YOGjf" aria-level="3" role="heading">
        Cita de conciliación y apelación
      </div>
      <div class="SHrHx">
        <div class="cEZxRc">sáb, 8:00 a.m.&thinsp;–&thinsp;11:30 p.m.</div>
        <div class="cEZxRc zvDXNd">
          en Junta de conciliación y arbitraje, Granjas México
        </div>
        <div class="cEZxRc zvDXNd">Ciudad de México, CDMX</div>
      </div>
    </div>

    <div class="i1D74d"></div>
  </div>
</div>
<br />
<div class="cgt">Centro de integración</div> <br />
<br />
<div
  elevation="0"
  spacing="s_32"
  class="sc-4wqkuy-0 kiqPyJ sc-1qa7ykr-1 drPIre"
>
  <div class="sc-1qa7ykr-2 jxQdvP"></div>
  <div>
    <div class="sc-1re405y-0 gMkjUi">
      <div class="sc-1re405y-1 cluFOw">
        COMPLETA TU PERFIL Y COMIENZA A APERTURAR DE INMEDIATO
      </div>
    </div>
    <div class="sc-k407ft-0 copjsf"></div>
    <br />
    <p>
      Explora nuestros distintos servicios legales, puedes aperturar una
      consulta, hacer una pregunta rapida o bien contratar un producto legal.
    </p>
    <div class="sc-k407ft-0 iOedXE"></div>
  </div>
</div>
<br><br>
<div
elevation="0"
spacing="s_32"
class="sc-ct"
>
<div class="cgt">Ultimas notificaciones</div><br>
<ul class="comma-list">
  <li>Su caso sobre Impagos se ha aperturado con exito</li>
  <li>
    Tiene mensajes nuevos en su chat con el despacho Ordoñez y Asociados
  </li>
  <li>Su ticket de soporte: 398hfd8, tiene una respuesta</li>
  <li>
    Su caso sobre consultoría en Impuestos y Pnesiones tiene varias propuestas
    de atención
  </li>
</ul>
</div>
</div>
<span (click)="openGroupModal(content)" id="btn"></span>

<!-- Start add group Modal -->
<ng-template #content let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel"></h5>
      <button
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body p-4">
      <div class="body2">
        <div>
          <div class="progress-wrap progress">
            <div class="progress-bar progress"></div>
          </div>

          <div class="body1">
            <div class="verticalCarousel">
              <ul class="verticalCarouselGroup vc_list">
                <li>
                  <div class="contenedor">
                    <div class="hijo">
                      <h4>Bienvenido a Cuartocastejo</h4>
                      <div class="ct">Te invitamos a concluir tu registro.</div>
                      <br /><br />
                      <div class="gLjXDS">
                        <br />
                        Podrías apoyarnos con un breve cuestionario no
                        debería<br />
                        tardar más de 3 minutos<br /><br />
                      </div>
                      <button
                        data-qa="start-button"
                        tabindex="0"
                        class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"
                      >
                        <span class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"
                          ><span
                            class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"
                            ><span class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ"
                              >Empecemos</span
                            ></span
                          ></span
                        >
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="contenedor">
                    <div class="hijo2">
                      <div class="ct1">
                        <br /><br />
                        <div class="question">
                          <p>¿Cuáles son mayormente tus necesidades legales?</p>
                          <mat-checkbox [(ngModel)]="item1"
                            >Acceso a la justicia</mat-checkbox
                          >
                          <mat-checkbox [(ngModel)]="item2"
                            >Derechos de propiedad
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Derechos laborales
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Justicia Penal </mat-checkbox
                          ><br />
                          <mat-checkbox [(ngModel)]="item3"
                            >Ley de Familia
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Inmigración
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Impuestos
                          </mat-checkbox>
                        </div>
                        <br /><br />
                        <button
                          data-qa="start-button"
                          tabindex="0"
                          class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"
                        >
                          <span class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"
                            ><span
                              class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"
                              ><span
                                class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ"
                                >Siguiente</span
                              ></span
                            ></span
                          >
                        </button>

                        <br /><br />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="contenedor">
                    <div class="hijo2">
                      <div class="ct1">
                        <br /><br />
                        <div class="question">
                          <p>¿Qué áreas legales son de tu interés?</p>
                          <mat-checkbox [(ngModel)]="item1"
                            >Propiedad Intelectual</mat-checkbox
                          >
                          <mat-checkbox [(ngModel)]="item2"
                            >Impagos
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Consultoría estratégica
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Penal
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Corporativo
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Laboral
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Fiscal y administrativo
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Bancario
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Civil
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Extranjeria
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Seguros
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Constitucional
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3">
                            Comercio exterior
                          </mat-checkbox>
                        </div>
                        <br /><br />
                        <button
                          data-qa="start-button"
                          tabindex="0"
                          class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"
                        >
                          <span class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"
                            ><span
                              class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"
                              ><span
                                class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ"
                                >Siguiente</span
                              ></span
                            ></span
                          >
                        </button>

                        <br /><br />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="contenedor">
                    <div class="hijo2">
                      <div class="ct1">
                        <br /><br />
                        <div class="question">
                          <p>
                            ¿Te gustaría recibir por correo Educación Legal y
                            Educación Financiera?
                          </p>
                          <mat-radio-group [(ngModel)]="checkoutOption">
                            <mat-radio-button value="si">Sí</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <br /><br />
                        <div class="question">
                          <p>¿Qué esperas de un servicio legal?</p>
                          <mat-checkbox [(ngModel)]="item1"
                            >Transparencia</mat-checkbox
                          >
                          <mat-checkbox [(ngModel)]="item2"
                            >Democratización
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Calidad-precio
                          </mat-checkbox>
                          <mat-checkbox [(ngModel)]="item3"
                            >Precios economicos
                          </mat-checkbox>

                          <mat-checkbox [(ngModel)]="item3"
                            >Honestidad del abogado
                          </mat-checkbox>
                        </div>
                        <br /><br />
                        <button
                          data-qa="start-button"
                          tabindex="0"
                          class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"
                        >
                          <span class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"
                            ><span
                              class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"
                              ><span
                                class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ"
                                >Siguiente</span
                              ></span
                            ></span
                          >
                        </button>

                        <br /><br />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="contenedor">
                    <div class="hijo2">
                      <h4 style="text-align: center">Planes preferenciales</h4>

                      <div class="ct1">
                        <div class="question">
                          <p>¿Tienes convenio con alguna de estás empresas?</p>
                          <mat-radio-group
                            [(ngModel)]="convenioOption"
                            (change)="convenioOption = 'GNP'"
                          >
                            <mat-radio-button value="GNP"
                              ><img
                                src="assets/images/logoGNP.png"
                                style="width: 81px; height: 39px"
                            /></mat-radio-button>
                            <mat-radio-button value="UpsiVale"
                              ><img
                                src="assets/images/upsiVale.png"
                                style="width: 76px; height: 27px"
                            /></mat-radio-button>
                            <mat-radio-button value="Neoris"
                              ><img
                                src="assets/images/neorisLogo.png"
                                style="width: 87px; height: 56px"
                            /></mat-radio-button>
                            <mat-radio-button value="MoradaUno"
                              ><img
                                src="assets/images/moradaUno.png"
                                style="width: 135px; height: 16px"
                            /></mat-radio-button>
                            <mat-radio-button value="Albo"
                              ><img
                                src="assets/images/albo-logo.jpg"
                                style="width: 56px; height: 22px"
                            /></mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <br />
                        <div class="form" *ngIf="convenioOption === 'GNP'">
                          <mat-form-field style="width: 100%">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Número de póliza"
                              [(ngModel)]="policyNumber"
                            />
                          </mat-form-field>
                        </div>

                        <button
                          data-qa="start-button"
                          tabindex="0"
                          class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"
                        >
                          <span class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"
                            ><span
                              class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"
                              ><span
                                class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ"
                                >Siguiente</span
                              ></span
                            ></span
                          >
                        </button>
                        <div class="TextWrapper-sc" style="float: right">
                          u
                          <strong _ngcontent-giw-c282="">Omitir ↵</strong>
                        </div>

                        <br /><br />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="contenedor">
                    <div class="hijo2">
                      <div class="ct1">
                        <br /><span class="title_success">Completado</span
                        ><br />
                        ¡Felicidades!, cómo compensación adicional a tu póliza
                        tienes los siguientes beneficios...<br /><br />

                        <svg height="13" width="16">
                          <path
                            d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"
                          ></path>
                        </svg>
                        Descuento preferencial del 15%<br />
                        <svg height="13" width="16">
                          <path
                            d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"
                          ></path>
                        </svg>
                        Apertura y seguimiento sin pago electrónico<br />
                        <svg height="13" width="16">
                          <path
                            d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"
                          ></path>
                        </svg>
                        Facilidades de pagos en casos de accidente de tráfico<br />
                        <svg height="13" width="16">
                          <path
                            d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"
                          ></path>
                        </svg>
                        Acceso a abogados altamente especializados para ti o tu
                        familia <br /><br />
                        <mat-checkbox [(ngModel)]="item3"
                          >HE LEÍDO Y ACEPTO LOS
                          <a href="terminos.html"
                            >TÉRMINOS Y CONDICIONES</a
                          > </mat-checkbox
                        ><br /><br />
                        <button
                          (click)="modal.dismiss('Cross click')"
                          class="vc_goDown ButtonWrapper-sc-__sc-1qu8p4z-0 IGcnZ"
                        >
                          <span class="FlexWrapper-sc-__sc-1qu8p4z-1 jqrpsF"
                            ><span
                              class="ButtonTextWrapper-sc-__sc-1qu8p4z-4 bNgWKn"
                              ><span
                                class="TextWrapper-sc-__sc-1f8vz90-0 hZUeEQ"
                                >Finalizar</span
                              ></span
                            ></span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="verticalCarouselHeader">
                <h3></h3>
                <span class="vc_goDown"
                  ><i class="fa fa-fw fa-angle-down btn"></i
                ></span>
                <span class="vc_goUp"
                  ><i class="fa fa-fw fa-angle-up btn"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- End add group Modal -->
