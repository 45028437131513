<div *ngIf="seccion === 'home'" #div class="{{scrollable}}">
  <div style="position:relative;">
    <div id="header" style="  background: linear-gradient(rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)), url({{imagenId}}) no-repeat center center / cover;">
    <textarea id="id1" style="display: none">{{imagenId}}</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarPractica()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left"
                width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
            <a href="javascript:;">
              <svg class="lnr-cog" fill="red" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                viewBox="0 0 24 24">
                <path
                  d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tamano">
    <h1 style="color:#FF0000">{{formatString(consulta.consulta_desc | lowercase)}}</h1>
    <p topmargin=0 style="color:#717D7E">{{consulta.informacion_apertura.ciudad}}, {{listComentarios.length}} comentarios</p>
    <h1 style="color:#050404">Reseña</h1>
    <p topmargin=0 style="color:#030303" class="Tamaño2">{{consulta.informacion_apertura.motivo_asesoria}}</p>
    <br><br>
    <h1 style="color:#050404">Opinion de abogados sobre el caso</h1>
    <br>
    <mat-card *ngFor="let comentario of listComentarios" style="margin-bottom: 20px;">
      <mat-card-content>
        <div class="d-flex doctor-info-details  justify-content-between">
          <div class="d-flex">
            <div class="media align-self-start">
              <img alt="image" width="80" height="80" src="/assets/images/users/foto-principal-consultoria-empresarial.jpg" class="rounded">
              <i class="flaticon-381-heart"></i>
            </div>
            <div class="media-body">
              <h2 class="title">{{comentario.lawyer.profile_lawyer.nombre}} de {{comentario.lawyer.profile_lawyer.sedes}}, México</h2>
              <span *ngIf="comentario.precio?.precioOriginal">
              <button class="boton-rojo">Promoción</button>
              <p class="mb-md-2 mb-sm-4">
                <span class="descuento">-15%</span> ${{comentario.precio.precioStripe.precio}} MXN<br>
                Precio de lista: <span class="precio">${{comentario.precio.precioOriginal}} MXN</span>
              </p>
            </span>
            <span *ngIf="!comentario.precio?.precioOriginal">
              <button class="boton-verde">Precio de lista</button>
              <p class="mb-md-2 mb-sm-4">
                ${{comentario.precio.precioStripe.precio}} MXN<br>
              </p>
            </span>
            </div>
          </div>
          <div class="text-md-end">
            <div class="dropdown mb-md-3">
              <button class="button-3" role="button" *ngIf="comentario.seleccionado === 'NOT'"
              (click)="solicitarConsulta(comentario.lawyer.id, comentario.lawyer.profile_lawyer, comentario);">Solicitar Consultoria</button>
            <button class="button-3" *ngIf="comentario.seleccionado === 'YES'" role="button" (click)="abrirSeguimiento(consulta.id);">Ver consulta</button>
            </div>
            <div class="star-review">
              <i class="fa fa-star text-orange me-1"></i>
              <i class="fa fa-star text-orange me-1"></i>
              <i class="fa fa-star text-orange me-1"></i>
              <i class="fa fa-star text-orange me-1"></i>
              <i class="fa fa-star text-gray me-1"></i>
              <span class="ms-3">238 reviews - <span (click)="ver(comentario);">Ver reviews</span></span>
            </div>
            <span>
              <mat-icon style="font-size: 13px;
              height: 15px;
              padding-top: 3px;">calendar_today</mat-icon>
              <span>Publicado el: 21 August 2021, 12:45 AM</span>
            </span>
          </div>
        </div>
        <div class="doctor-info-content">
          {{comentario.comentario}}
        </div>
      </mat-card-content>
    </mat-card>
    <!--<div *ngFor="let comentario of listComentarios">
      <div>
        <h1 style="color:#FF0000">
          <img src="/assets/images/users/foto-principal-consultoria-empresarial.jpg" class="redondo-izquierda">
          <p class="centrado1">{{comentario.lawyer.profile_lawyer.nombre}}<br><span (click)="ver(comentario);">Ver reviews</span><br></p>
          <p class="centrado1" style="color:#717D7E">{{comentario.lawyer.profile_lawyer.sedes}}, México<br></p>
          <div class="btn-right">
            <button class="button-3" role="button" *ngIf="comentario.seleccionado === 'NOT'"
              (click)="solicitarConsulta(comentario.lawyer.id, comentario.lawyer.profile_lawyer, comentario.id);">Solicitar Consultoria</button><br>
            <button class="button-3" *ngIf="comentario.seleccionado === 'YES'" role="button" (click)="abrirSeguimiento(consulta.id);">Ver consulta</button>
          </div>
        </h1>
      </div>
      <div style="color:#000000" class="centrado2">{{comentario.comentario}}</div>
      <br><br>
    </div>-->
  </div>
</div>
</div>
<ng-container #detalle></ng-container>
