<div class="t-comments-box">
    <div class="comment-title">
        Comentarios de clientes sobre {{despacho.despacho.lawyer.profile_lawyer.nombre}}
    </div>
    <mat-tab-group [color]="colorToggle" [backgroundColor]="backgroundColorToggle">
        <mat-tab label="Reviews">
            <div style="overflow-x: scroll; height: 380px;">
            <div class="add-comment">
                <div class="comment-input">
                    <input class="form-control form-control-sm" [(ngModel)]="comentario" type="text" placeholder="Add a comment.." />

                    <div class="cont">
                        <div class="stars" style="text-align: center;">
                          <ngb-rating [max]="5" [(rate)]="rating3" [readonly]="false"></ngb-rating>
                        </div>
                    </div>
                    <mat-dialog-actions align="end">
                        <button mat-button mat-dialog-close>Cancelar</button>
                        <button id="btnSubmit" mat-raised-button (click)="altaReviews();" [disabled]="!rating3 || !comentario" type="submit" color="primary" cdkFocusInitial>Añadir
                            comentario</button>
                    </mat-dialog-actions>
                    <div class="comment-send"><i class="fab fa-telegram-plane"></i></div>
                </div>
            </div>
            <div *ngFor="let item of reviews" class="comment comment-level-1">
                <img src="/assets/images/users/foto-principal-consultoria-empresarial.jpg" alt="" />
                <div>
                    <div class="comment-label">
                        <span class="comment-name">{{item.escrito_por}}</span>
                        <span class="comment-time"> - {{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</span>
                    </div>
                    <div class="comment-content">
                        {{item.comentario}}
                    </div>
                    <div class="comment-action">
                        <span>{{item.ranking}} stars</span>
                    </div>
                </div>
            </div>
            </div>
        </mat-tab>
        <mat-tab label="Acerca de">
            <div style="overflow-x: scroll; height: 380px;">
            <mat-card class="example-card">
                <mat-card-title>{{despacho.despacho.lawyer.profile_lawyer.nombre}}</mat-card-title>
                <mat-card-content>
                  <p>
                    {{despacho.despacho.lawyer.profile_lawyer.descripcion}}
                  </p>
                </mat-card-content>
              </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
