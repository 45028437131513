import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../account/auth/login/login.component';
import { AuthRedirectComponent } from '../auth-redirect/auth-redirect.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    { path: 'inicio', loadChildren: () => import('./index/index.module').then(m => m.IndexModule) },
    { path: 'abrir-consulta/:idPractica', loadChildren: () => import('./tabs/abrirconsulta/abrirconsulta.module').then(m => m.AbrirconsultaModule) },
    { path: 'chats', loadChildren: () => import('./tabs/chats/chats.module').then(m => m.ChatsModule) },
    { path: 'chattheme', loadChildren: () => import('./tabs/themechat/themechat.module').then(m => m.ThemechatModule) },
    { path: 'seguimiento', loadChildren: () => import('./tabs/misconsultas/seguimiento/seguimiento.module').then(m => m.SeguimientoModule) },
    { path: 'home', loadChildren: () => import('./tabs/home/home.module').then(m => m.HomeModule) },
    { path: 'practica/:idPractica', loadChildren: () => import('./tabs/abrirconsulta/practica/practica.module').then(m => m.PracticaModule) },
    { path: 'reclamaciones-indemnizaciones', loadChildren: () => import('./tabs/reclamaciones-indemnizaciones/reclamaciones-indemnizaciones.module').then(m => m.ReclamacionesIndemnizacionesModule) },
    { path: 'detalle/:id', loadChildren: () => import('./tabs/abrirconsulta/detalle/detalle.module').then(m => m.DetalleModule) },
    { path: 'apertura', loadChildren: () => import('./tabs/abrirconsulta/apertura/apertura.module').then(m => m.AperturaModule) },
    { path: 'productos', loadChildren: () => import('./tabs/productos/productos.module').then(m => m.ProductosModule) },
    { path: 'consultas', loadChildren: () => import('./tabs/misconsultas/misconsultas.module').then(m => m.MisconsultasModule) },
    { path: 'facturas-recibos', loadChildren: () => import('./tabs/facturas-recibos/facturas-recibos.module').then(m => m.FacturasRecibosModule) },
    { path: 'investigacion', loadChildren: () => import('./tabs/investigacion/investigacion.module').then(m => m.InvestigacionModule) },
    { path: 'consulta', loadChildren: () => import('./tabs/misconsultas/consulta/consulta.module').then(m => m.ConsultaModule) },
    { path: 'white', loadChildren: () => import('./tabs/blank/blank.module').then(m => m.BlankModule) },
    { path: 'smart-contracts', loadChildren: () => import('./tabs/smart-contracts/smart-contracts.module').then(m => m.SmartContractsModule) },
    { path: 'info-comercial-general', loadChildren: () => import('./tabs/smart-contracts/info-comercial-general/info-comercial-general.module').then(m => m.InfoComercialGeneralModule) },
    { path: 'home-contracts', loadChildren: () => import('./tabs/smart-contracts/home-contracts/home-contracts.module').then(m => m.HomeContractsModule) },
    { path: 'soporte', loadChildren: () => import('./tabs/soporte/soporte.module').then(m => m.SoporteModule) },
    { path: 'informacion', loadChildren: () => import('./tabs/soporte/informacion/informacion.module').then(m => m.InformacionModule) },
    { path: 'preguntasrapidas', loadChildren: () => import('./tabs/preguntasrapidas/preguntasrapidas.module').then(m => m.PreguntasrapidasModule) },
    { path: 'perfil', loadChildren: () => import('./tabs/perfil/perfil.module').then(m => m.PerfilModule) },
    { path: 'comunidad', loadChildren: () => import('./tabs/soporte/comunidad/comunidad.module').then(m => m.ComunidadModule) },
    { path: 'smart-contracts-contrato', loadChildren: () => import('./tabs/smart-contracts/smart-contracts-contrato/smart-contracts-contrato.module').then(m => m.SmartContractsContratoModule) },
    { path: 'automatizacion-documento', loadChildren: () => import('./tabs/smart-contracts/automatizacion-documento/automatizacion-documento.module').then(m => m.AutomatizacionDocumentoModule) },
    { path: 'generacion-contrato', loadChildren: () => import('./tabs/smart-contracts/generacion-contrato/generacion-contrato.module').then(m => m.GeneracionContratoModule) },
    { path: 'firma-digital', loadChildren: () => import('./tabs/smart-contracts/firma-digital/firma-digital.module').then(m => m.FirmaDigitalModule) },
    { path: 'flujo-trabajo', loadChildren: () => import('./tabs/smart-contracts/flujo-trabajo/flujo-trabajo.module').then(m => m.FlujoTrabajoModule) },
    { path: 'instrucciones-contrato', loadChildren: () => import('./tabs/smart-contracts/instrucciones/instrucciones.module').then(m => m.InstruccionesModule) },
    { path: 'firma-contratista', loadChildren: () => import('./tabs/smart-contracts/firma-contratista/firma-contratista.module').then(m => m.FirmaContratistaModule) },
    { path: 'firma-dashboard', loadChildren: () => import('./tabs/smart-contracts/firma-dashboard/firma-dashboard.module').then(m => m.FirmaDashboardModule) },
    { path: 'clausulas-dashboard', loadChildren: () => import('./tabs/smart-contracts/clausulas-dashboard/clausulas-dashboard.module').then(m => m.ClausulasDashboardModule) },
    { path: 'template-dashboard', loadChildren: () => import('./tabs/smart-contracts/template-dashboard/template-dashboard.module').then(m => m.TemplatesDashboardModule) },
    { path: 'auth-redirect', component: AuthRedirectComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class ChatRoutingModule { }
