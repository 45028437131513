<div class="p-2" *ngIf="seccion === 'home'">

  <mat-tab-group
    [color]="colorToggle.value"
    [backgroundColor]="backgroundColorToggle.value"
  >
    <mat-tab label="Aperturar consulta">
      <br><br>
      Consultoría legal<br /><br />
      <mat-chip-list aria-label="Selection">
        <mat-chip *ngFor="let item of legal" (click)="abrirDetalle(item.id)">
          <img matChipAvatar src="{{ item.imagen }}" alt="Photo" />
          {{ item.nombre }}
        </mat-chip> </mat-chip-list
      ><br /><br />
      Consultoría estrátegica<br /><br />
      <mat-chip-list aria-label="Selection">
        <mat-chip
          *ngFor="let item of estrategica"
          (click)="abrirDetalle(item.id)"
        >
          <img matChipAvatar src="{{ item.imagen }}" alt="Photo" />
          {{ item.nombre }}
        </mat-chip>
      </mat-chip-list>
    </mat-tab>
    <mat-tab label="Reclamaciones e indemnizaciones">
      <br /><br />
      <ul class="comma-list">
        <li *ngFor="let item of reclamaciones" (click)="abrirDetalle(item.id)">
          {{ item.nombre }}
        </li>
      </ul>
    </mat-tab>
    <mat-tab label="Productos">
      <br /><br />
      <ul class="comma-list">
        <li *ngFor="let item of productos" (click)="abrirDetalle(item.id)">
          {{ item.nombre }}
        </li>
      </ul>
    </mat-tab>
    <mat-tab label="Servicios notariales">
      <br /><br />
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <mat-card class="mb-4">
              <mat-card-header>
                <mat-card-title>Derecho Mercantil y Corporativo</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                Constitución de Sociedades, Fusión, Escisión, Transformación, Protocolización de Actas de Asamblea y Consejo.
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-md-6 col-lg-6">
            <mat-card class="mb-4">
              <mat-card-header>
                <mat-card-title>Derecho Civil</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                Testamentos, Poderes, Revocación de poderes, Sucesiones Testamentarias, Sucesiones Intestamentarias, Cesiones de Derechos (principalmente litigiosos y/o adjudicatarios).
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <mat-card class="mb-4">
              <mat-card-header>
                <mat-card-title>Derecho Inmobiliario</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                Compraventa, Donación, Permuta, Condominios, Aportación a sociedades, Adjudicaciones por herencia, Adjudicaciones por Remate Judicial, Formalización de Transmisiones de Propiedad (por divorcio, por juicios ordinarios civiles de otorgamiento y firma de escrituras), Fideicomisos, Hipotecas, Cancelación de Hipotecas, Fusión y Subdivisión de predios.
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-md-6 col-lg-6">
            <mat-card class="mb-4">
              <mat-card-header>
                <mat-card-title>Otros</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                Cotejo de documentos y Expedición de copias certificadas, Notificaciones, Interpelaciones, Fe de hechos, Ratificaciones, Declaraciones, Apostillamientos, Legalizaciones, Transmisión de Propiedad en Ejecución de Fideicomiso, Daciones en Pago, Servicios de Mediación, Negociación y Solución de Conflictos.
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <br />

  <div class="et_pb_section et_pb_section_3 et_section_regular">
    <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_11">
      <div class="et_pb_module_inner">
        <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
          <div
            class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center"
          >
            <div
              class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center"
              data-svg-shape=""
              data-shape-size="contain"
              data-use-upload="off"
              data-uploaded-mask=""
            >
              <img
                src="assets/images/automation-tools-homepage-min.webp"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="">
              <div class="dnxt-blurb-container">
                <div class="dnxt-blurb-heading-wrapper">
                  <h2 class="dnxt-blurb-heading">
                    Reclamaciones e Indemnizaciones
                  </h2>
                </div>
                <div class="dnxt-blurb-description">
                  <p>
                    Aperture una reclamación o indemnización con nuestro modelo
                    de negocio win to win, solo cobramos si recuperamos lo
                    reclamado.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_10">
      <div class="et_pb_module_inner">
        <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
          <div
            class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center"
          >
            <div
              class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center"
              data-svg-shape=""
              data-shape-size="contain"
              data-use-upload="off"
              data-uploaded-mask=""
            >
              <img
                src="assets/images/hire-a-lawyer-homepage-min.webp"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="">
              <div class="dnxt-blurb-container">
                <div class="dnxt-blurb-heading-wrapper">
                  <h2 class="dnxt-blurb-heading">Contratar a un abogado</h2>
                </div>
                <div class="dnxt-blurb-description">
                  <p>
                    Nuestros abogados expertos aman lo que hacen y siempre están
                    listos para brindarle el asesoramiento que necesita para
                    seguir construyendo su negocio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_9">
      <div class="et_pb_module_inner">
        <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
          <div
            class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center"
          >
            <div
              class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center"
              data-svg-shape=""
              data-shape-size="contain"
              data-use-upload="off"
              data-uploaded-mask=""
            >
              <img
                src="assets/images/Legal-Documents-homepage-min.webp"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="">
              <div class="dnxt-blurb-container">
                <div class="dnxt-blurb-heading-wrapper">
                  <h2 class="dnxt-blurb-heading">Consultoría estrátegica</h2>
                </div>
                <div class="dnxt-blurb-description">
                  <p>
                    Podemos ser tu CTO, CFO, CMO, COO, CCO 24/7, que te
                    orientaremos en diversas areas y practicas de tu negocio o
                    emprendimiento.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_8">
      <div class="et_pb_module_inner">
        <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
          <div
            class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center"
          >
            <div
              class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center"
              data-svg-shape=""
              data-shape-size="contain"
              data-use-upload="off"
              data-uploaded-mask=""
            >
              <img
                src="assets/images/start-a-business-homepage-min.webp"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="">
              <div class="dnxt-blurb-container">
                <div class="dnxt-blurb-heading-wrapper">
                  <h2 class="dnxt-blurb-heading">Iniciar un negocio</h2>
                </div>
                <div class="dnxt-blurb-description">
                  <p>
                    <span style="font-weight: 400"
                      >Toma tu idea y conviértela en un negocio registrando una
                      empresa usando Cuartocastejo. Te guiamos a través de todo
                      lo que necesitas para tu negocio.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br /><br /><br />
  <div class="img_center">
    <span class="centrado">¿Como funciona?</span>
    <br />  <br />


    <div class="grid-container">

    <div class="col">
      <img src="https://cuartocastejo.com/legal/who1.png" alt="logo" />
      <h1 class="centrado" style="color: #ff0000">Apertura</h1>
      <p class="centrado" style="color: #000000">
        Haz tu consulta en menos de 1 minuto recibiras comentarios preliminares
        de abogados especializados, posteriormente revisa sus perfiles y sus
        reviews y toma la decisión que más confianza te genere.
      </p>
    </div>
    <div class="col">
      <img src="https://cuartocastejo.com/legal/who2.png" alt="logo" />
      <h1 class="centrado" style="color: #ff0000">Recibe propuestas</h1>
      <p class="centrado" style="color: #000000">
        Recibe comentarios preliminares de nuestros abogados. Junto a la mejor
        propuesta y elige la que mas te guste.
      </p>
    </div>

    <div class="col">
      <img src="https://cuartocastejo.com/legal/who3.png" alt="logo" />
      <h1 class="centrado" style="color: #ff0000">Efectua el pago</h1>
      <p class="centrado" style="color: #000000">
        Realiza el pago con tu tarjeta de credito o debito y accede a una o más
        asesorias personalizadas.
      </p>
    </div>
    <div class="col">
      <img src="https://cuartocastejo.com/legal/who4.png" alt="logo" />
      <h1 class="centrado" style="color: #ff0000">Seguimiento</h1>
      <p class="centrado" style="color: #000000">
        Recibe retroalimentación a traves de expedientes, o realiza chatea o ten
        una videollamada con un abogado.
      </p>
    </div>
    </div>
  </div>


</div>

<ng-container #practica></ng-container>
<ng-container #detalle></ng-container>
<ngx-loading-x [show]="load"></ngx-loading-x>
