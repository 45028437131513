import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-preguntasrapidas',
  templateUrl: './preguntasrapidas.component.html',
  styleUrls: ['./preguntasrapidas.component.scss']
})

/**
 * Tabs-group component
 */
export class PreguntasrapidasComponent implements OnInit {

  public isCollapsed: boolean;
  seccion = 'home';
  pregunta;
  descripcion;
  nickname;
  idUsuario;
  respuestas;
  preguntasList;
  error;
  infoPregunta;

  groups: any[];

  constructor(private modalService: NgbModal,  public dialog: MatDialog, private publicService: PublicService, ) { }

  ngOnInit(): void {
    // collpsed value
    this.isCollapsed = true;
    this.publicService.get_perfil_users_profile().subscribe(data => {
      console.log(data);
      this.nickname = data.profile_user.nombre;
      this.idUsuario = data.id_authguard;
    }, error => console.log(error));
    this.publicService.get_preguntas().subscribe(data => {
      console.log(data);
      this.preguntasList = data.items;
    }, error => console.log(error));
  }

  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(info: any, item) {
    this.modalService.open(info, { centered: true });
    this.infoPregunta = item;
    this.publicService.get_respuestas(item.id).subscribe(data => {
      console.log(data);
      this.respuestas = data.items;
    }, error => console.log(error));
  }

  openPreguntaDetail(info: any) {
    this.modalService.open(info, { centered: true });
  }

  createQuestion(pregunta, desc) {
    console.log(pregunta, desc);
    let obj = {
      'motivo': pregunta,
      'descripcion': desc,
      'autor': this.nickname,
      'avatar': '',
      'idUsuario': this.idUsuario
    };
    this.publicService.post_pregunta(obj).subscribe(data => {
      window.location.reload();
    }, error => this.error = error);
  }
}



@Component({
  selector: 'ConfirmAlert',
  template: `<h1 mat-dialog-title>¿Desea levantar este ticket?</h1>
  <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Gracias</button>
  <button mat-button [mat-dialog-close]="data.isTrue" cdkFocusInitial>Si</button>
</div>`,
})
export class ConfirmAlert {
  constructor(public dialogRef: MatDialogRef<ConfirmAlert>, @Inject(MAT_DIALOG_DATA) public data: DialogD,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogD {
  isTrue: boolean;
}
