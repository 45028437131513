import { Component, OnInit, ViewContainerRef, ViewChild, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // <----
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../themechat/themechat.component';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { PublicService } from 'src/app/public.service';
import { MatDialog } from '@angular/material/dialog';

export interface FacturaElement {
  creacion: string;
  monto: number;
  estatus: string;
  acciones: string;
}

@Component({
  selector: 'app-facturas-recibos',
  templateUrl: './facturas-recibos.component.html',
  styleUrls: ['./facturas-recibos.component.scss']
})
export class FacturasRecibosComponent implements OnInit {
  @ViewChild('contenido', { read: ViewContainerRef }) practica: ViewContainerRef;
  seccion = 'home';
  load = false;
  private _ref;
  cards;
  error;
  displayedColumns: string[] = ['monto', 'estatus', 'acciones'];
  dataFacturas;
  dataRecibos;
  ELEMENT_DATA: FacturaElement[] = [];

  constructor(private publicService: PublicService, private http: HttpClient, private _bottomSheet: MatBottomSheet) {
  }

  ngOnInit(): void {
    //Carga facturas
    this.publicService.get_facturas('list').subscribe(data => {
      const dataS = data.data;
      const arrFacturas = [];
      for (const row of dataS) {
        arrFacturas.push({monto: row.factura_total, creacion: row.created_at, estatus: row.status, acciones: row.cfdi_uuid});
      }

      this.dataFacturas = arrFacturas;
   },error => console.log(error));

    //Carga recibos
    this.publicService.get_paybranch('saldos').subscribe(data => {
      const dataS = data.items;
      const arrRecibos = [];
      for (const row of dataS) {
        arrRecibos.push({monto: row.monto, creacion: row.createdAt, estatus: row.status, acciones: row.idRecibo, id: row.id});
      }

      this.dataRecibos = arrRecibos;
   },error => console.log(error));
  }

  abrirAccionesFactura (idFactura): void {
    this._bottomSheet.open(BottomSheetOverviewExampleSheet, { data: { names: idFactura, type: 'FACTURA', idSaldo: 0 }});
  }

  abrirAccionesRecibo (idFactura, idSaldo): void {
    this._bottomSheet.open(BottomSheetOverviewExampleSheet, { data: { names: idFactura, type: 'RECIBO', idSaldo: idSaldo }});
  }
}
@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  template: `<div *ngIf="tipoComprobante === 'FACTURA'" class="sheet">
  <br>
  <h1>Enviar XML/PDF</h1>
  <form class="example-form">
  <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
    <mat-label>Email</mat-label>
    <input type="email" matInput [formControl]="emailFormControl" maxlength="45" [(ngModel)]="email" [errorStateMatcher]="matcher"
           placeholder="Ex. pat@example.com">
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
    Por favor, introduce una dirección de correo electrónico válida    </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('required')">
      Email es <strong>rquerido</strong>
    </mat-error>
  </mat-form-field>
</form>
  <button class="matBtn" [disabled]="dsBtnEmail || !email" (click)="enviarEmail(email);">Enviar a mi email</button><br>
</div>
<div *ngIf="tipoComprobante === 'RECIBO'" class="sheet">
  <br>
  <button class="matBtn" color="warn"  [disabled]="dsBtnCancelar" (click)="facturarRecibo(idFactura, idSaldo)">Facturar recibo</button><br><br>
</div>`,
  styleUrls: ['./facturas-recibos.component.scss']
})
export class BottomSheetOverviewExampleSheet {
  motivos = [{clave: '01', name: 'Comprobante emitido con errores con relación'},
  {clave: '02', name: 'Comprobante emitido con errores sin relación'},
  {clave: '03', name: 'No se llevó a cabo la operación'}];
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();
  idFactura;
  motivo = '03';
  email;
  tipoComprobante;
  dsBtnCancelar = false;
  dsBtnEmail = false;
  idSaldo;

  constructor(public publicService: PublicService, public dialog: MatDialog, private http: HttpClient, private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: {names: string[], type: string, idSaldo: number}) {
    this.idFactura = data.names;
    this.idSaldo = data.idSaldo;
    this.tipoComprobante = data.type;
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  cancelarFactura() {
    this.dsBtnCancelar = true;
    const dialogRef = this.dialog.open(alertaCancelacionFacturas);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const request = {
          'id': this.idFactura,
          'motive': this.motivo
        };
        this.publicService.post_facturas('cancel', request).subscribe(data => {
          window.location.reload();
       },error => console.log(error));
      } else {
        this.dsBtnCancelar = false;
      }
    });
  }

  facturarRecibo(factura, recibo) {
    this.dsBtnCancelar = true;
    const dialogRef = this.dialog.open(alertaFacturarRecibo);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const request = {
          'id': factura,
        };
        this.publicService.post_facturas('facturar-recibo', request).subscribe(data => {
          this.publicService.patch_paybranch(recibo, {estatusRecibo: 'FACTURADO'}).subscribe(res => {
          window.location.reload();
        },error => console.log(error));

       },error => this.dialog.open(alertaError));
      } else {
        this.dsBtnCancelar = false;
      }
    });
  }

  enviarEmail(email) {
    this.dsBtnEmail = true;
    const dialogRef = this.dialog.open(alertaEnviarFactura);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.publicService.get_facturas('enviar-factura/'+this.idFactura+'/'+email).subscribe(data => {
          window.location.reload();
       },error => console.log(error));
      } else {
        this.dsBtnEmail = false;
      }
    });
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `<h2 mat-dialog-title>Confirmación</h2>
  <mat-dialog-content class="mat-typography">
    <h3>¿Desea cancelar esta factura?</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Si</button>
  </mat-dialog-actions>`,
})
export class alertaCancelacionFacturas {}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `<h2 mat-dialog-title>Confirmación</h2>
  <mat-dialog-content class="mat-typography">
    <h3>¿Desea enviar esta factura al correo señalado?</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Si</button>
  </mat-dialog-actions>`,
})
export class alertaEnviarFactura {}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `<h2 mat-dialog-title>Confirmación</h2>
  <mat-dialog-content class="mat-typography">
    <h3>¿Desea facturar este recibo?</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Si</button>
  </mat-dialog-actions>`,
})
export class alertaFacturarRecibo {}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `<h2 mat-dialog-title>Error</h2>
  <mat-dialog-content class="mat-typography">
    <h3>Este recibo ya no puede ser facturado</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Ok</button>
  </mat-dialog-actions>`,
})
export class alertaError {}
